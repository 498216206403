
@media (max-width: 1350px) {
    .quick_menu_box {
        display: none;
    }
}
@media (max-width: 1300px) {
    .header_top_pop, .header_part1, .header_part2, .header_part3,
    .footer_part1 .footer_top{
        padding: 0 20px;
    }
    .footer_part2 {
        padding: 20px;
    }
    
    .header_part3_cate_box .tap.two {
        font-size: 18px;
    }

    .main_part, .main_content3, .main_content4 {
        padding: 0 20px;
    }
    
    /* .main_swiper_box,
    .main_content3 .bottom_other_box {
        padding-right: 30px;
    }
    .main_content1, .learning_data_box, .main_content4 {
        margin-right: 30px;
    } */
    .main_swiper1 {
        /* aspect-ratio: 390 / 160 !important; */
        max-height: 320px !important;
        height: 100% !important;
    }

    
}


@media (max-width: 1250px) {
    .main_content2 {
        width: 100%;
    }
    .learning_data_box {
        padding: 0;
        border: none;
    }
    .main_content2 {
        padding: 0 20px;
    }
    .learning_data .post_list {
        display: none;
    }
    .main_swiper3.mobile {
        display: block !important;
        width: 100%;
    }
    .main_swiper3.mobile .swiper-slide {
        max-width: 208px !important;
        width: 100% !important;
    }

    .main_swiper3_mobile {
        display: block !important;
        width: 100% !important;
    }
    .main_swiper3_mobile .post_box.swiper,
    .main_swiper3_mobile .post_box.swiper .post_back {
        max-width: unset;
        height: unset;
    }
    /* .main_swiper3_mobile .post_box.swiper .post_back {
        max-width: unset;
    } */
}

@media (max-width: 1200px) {
    .header_top_pop .content img.close {
        left:unset;
        right:0px;
    }

    .main_content1 {
        gap: 20px;
    }
    .content_box .img_box,
    .shortcut_box .shortcut {
        aspect-ratio: 1 / 1;
        height: auto;
    }
    .content_box .img_box img {
        width: 70px;
        height: auto;
    }
    .bottom_other_box {
        flex-direction: column;
    }
    .bottom_other_box .event_box {
        max-width: unset;
        justify-content: space-between;
    }
    .bottom_other_box .event_box img {
        width:100%;
    }
    .bottom_other_box .event_box .title_box,
    .bottom_other_box .update_box,
    .update_box_swiper {
        max-width: unset !important;
    }
    .sun-editor-editable img{width:auto !important;height: auto !important}
}

@media (max-width: 1100px) {
    .main_content4 .service_box {
        display: none;
    }
    .main_content4 .service_box.mobile.one {
        display: flex !important;
        flex-direction: column;
        height: unset;
    }
    .service_box_group {
        display: flex;
    }
    .main_content4 .service_box .service_tap.notice {
        padding-bottom: 14px;
    }
    /* .service_box {
        flex-direction: column;
    }
    .service_box_mobile {
        display: flex !important;
        align-items: center;
    } */
    .main_content4 .service_box .service_tap {
        max-width: unset !important;
    }
}
@media (max-width: 1000px) {
    .header_part1,
    .header_part2, .header_part3 {
        padding: 0 20px;
    }
    .header_part3_cate_box .tap.two {
        font-size: 16px;
    }



    /* .main_box {
        padding: 0 0 100px 0;
    } */
    .main_content2 {
        padding-right: 0;
    }
    .title_box.swiper_title .margin_btn {
        margin-right: 20px;
    }
    .service_tap.notice .notice_post_list .notice_post .title {
        max-width: 250px;
    }
}

@media (max-width: 950px) {
    .main_content1 {
        flex-direction: column;
        gap: 40px;
    }
    .main_content1 .line {
        display: none;
    }
    .main_content1 .main_centent1_sub1._2.pc {
        display: none;
    }
    .main_content1 .main_centent1_sub1._2.mobile {
        display: flex !important;
    }
    .main_content1 .main_centent1_sub1 {
        max-width: unset;
    }
    .main_centent1_sub1 .content_box,
    .main_centent1_sub1._2 .shortcut_box {
        justify-content: center;
        width: 100%;
    }
    .content_box .img_box img {
        width: 120px;
    }
    .shortcut_box .shortcut img {
        width: 100px;
    }
    .subject_box .img_box ,
    .content_box .subject_box,
    .shortcut_box .shortcut {
        max-width: unset;
    }
    .subject_box .comment,
    .shortcut_box .text2 {
        font-size: 16px;
    }
    .shortcut_box .text1 {
        font-size: 25px;
    }

    
}
@media (max-width: 850px) {
    .service_tap.notice .notice_post_list .notice_post .title {
        max-width: 200px;
    }
}

@media (max-width: 800px) {
    
    .header_part2.pc, .header_part3.pc {
        display: none;
    }
    .header_part2.mobile {
        display: flex !important;
        width: 100%;
    }
    .header_part2_mobile_back {
        display: flex !important;
        justify-content: space-between;
        height: 80px;
        max-width: 1200px;
        width: 100%;
    }
    .header_part2.mobile img {
        width: 35px; /* max 20px */
        height: auto;
    }

    .default_box {
        padding-top: 208px;
    }
    .default_box.banner {
        padding-top: 148px;
    }
    .header_cate_hover_menu.banner {
        top: calc(100% + 70px);
    }
}


@media (max-width: 769px) {
    .header_part1,
    .header_part2, .header_part3 {
        padding: 0 20px;
    }
    .header_part2.pc {
        display: none;
    }
    
    .header_part2.mobile .header_top_sub img {
        width: 35px; /* max 20px */
        height: auto;
    }
    .header_title_box .title {
        font-size: 30px; /* max 20px */
    }
    .header_title_box .sub_title_box .sub_title.sm {
        font-size: 12px; /* max 10px */
    }
    .header_title_box .sub_title_box .sub_title {
        font-size: 16px; /* max 14px */
    }
    .footer_btm_box {
        gap: 12px;
    }



    .default_box {
        padding-top: 98px;
    }
    .default_box.banner {
        padding-top: 38px;
    }
    .main_move_top {
        right: 5%;
    }
    .main_box {
        margin-top:60px;
        padding-top: 0;
        padding-bottom: 0;
    }
    .main_part.banner {
        padding: 0;
    }
    .main_swiper_box .main_swiper1_next,
    .main_swiper_box .main_swiper1_prev {
        display: none;
    }
    .main_swiper1 {
        border-radius: 0 !important;
    }
    .custom-pagination {
        right: 7px;
        bottom: 7px;
    }
    .learning_data_box {
        gap: 20px;
    }
    .main_content3, .main_content4 {
        padding: 20px;
        margin: 0;
    }
    .content_box .img_box img {
        width: 92px;
    }
    .main_part.lesson {
        padding: 20px;
    }

    .main_content1 {
        gap: unset;
        margin: 0;
    }
    .main_centent1_sub1.pc .content_box {
        display: none;
    }
    .main_swiper2.mobile {
        display: block !important;
        width: 100%;
        margin-bottom: 40px;
    }
    .subject_box.swiper, .subject_box .img_box {
        max-width: unset;
        height: unset;
    }
    .shortcut_box .text1 {
        font-size: 20px;
    }
    .shortcut_box .shortcut img {
        width: 50px;
    }
    .shortcut_box .text2 {
        font-size: 14px;
    }
    .main_content2 {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .service_box_group {
        flex-direction: column;
        gap: 20px;
    }
    .service_box_group .service_tap {
        border-right: none !important;
        padding: 0 0 20px 0!important;
        border-bottom: 1px solid #ddd;
    }
    .service_box_group .service_tap.client.plus {
        padding: 20px 0 !important;
    }
    .service_box_group .service_tap.client.last {
        border-bottom: none;
    }
    .service_box.mobile {
        border: none;
    }
    .service_tap.notice .notice_post_list .notice_post .title {
        max-width: unset;
        padding-right: 20px;
    }
}

@media (max-width: 690px) {
    .main_content1 .main_centent1_sub1._2.mobile {
        padding-right: 20px;
    }
}

@media (max-width: 600px) {
    .header_part2 .header_title_box {
        gap: 8px;
    }
    .header_title_box .title {
        font-size: 20px; /* max 20px */
    }
    .header_title_box .sub_title_box .sub_title.sm {
        font-size: 12px; /* max 10px */
    }
    .header_title_box .sub_title_box .sub_title {
        font-size: 18px; /* max 14px */
    }
}

@media (max-width: 550px) {
    
    .header_cate_hover_menu.banner {
        top: calc(100% + 80px);
    }
    .header_top {
        font-size: 12px;
    }
    .header_top_sub span {
        margin: 0 8px !important;
    }
    .header_top_sub .fav_btn {
        width: unset;
        gap: 4px;
    }
        
    .header_part2_mobile_back {
        height: 56px;
    }
    .header_part2.mobile .header_top_sub img {
        width: unset;
    }
    .header_title_box .title {
        font-size: 20px; /* max 20px */
    }
    .header_title_box .sub_title_box .sub_title.sm {
        font-size: 10px; /* max 10px */
    }
    .header_title_box .sub_title_box .sub_title {
        font-size: 14px; /* max 14px */
    }
    .header_title_box .sub_title_box {
        gap: unset;
    }

    .default_box {
        padding-top: 74px;
    }
    .default_box.banner {
        padding-top: 14px;
    }

    .subject_box .img_box {
        padding: 12px;
    }
    .subject_box .comment {
        font-size: 14px;
    }
    .shortcut_box .text1 {
        font-size: 16px;
    }
    .shortcut_box .shortcut img {
        width: unset;
    }
}

@media (max-width: 500px) {

    .header_cate_hover_menu.banner {
        top: calc(100% + 90px);
    }
    .header_cate_hover_menu.mnavi {
        top: calc(100% + 70px);
    }
    .header_part1 {
        height: 32px;
    }
    .header_part1 img {
        width: 24px;
        height: 24px;
    }
    .header_top_sub .fav_btn {
        display: flex;
        align-items: center;
    }
    .header_top_sub .fav_btn img {
        width: 16px;
        height: 16px;
    }
    .header_top_pop .content .title {
        font-size: 12px;
    }
    .header_top_pop .content .sub_title {
        font-size: 14px;
    }
    .footer_top_sub {
        gap: 8px;
    }
    .footer_top_sub select {
        width: 140px;
    }
    .footer_btm_box .text_box .btm_text.pc {
        display: none;
    }
    .footer_btm_box .text_box .btm_text.mobile {
        display: inline-block !important;
        font-size: 13px;
        margin: 8px 0;
        line-height: 15px;
    }
    .footer_btm_box .text_box .two {
        font-size: 13px;
    }

    .default_box {
        padding-top: 58px;
    }
    .default_box.banner {
        padding-top: 0px;
    }
    .main_centent1_sub1 .title_box,
    .learning_data_box .title_box,
    .main_centent1_sub1._2 .comment {
        margin-bottom: 12px;
    }
    
}
@media (max-width: 400px) {
    .header_top_pop, .header_part1 {
        padding: 0 12px;
    }
}
@media (max-width: 360px) {
    .header_part1 img {
        width: 20px;
        height: 20px;
        margin: 0 !important;
    }
    .header_top_sub.right {
        gap: 4px;
    }
    .header_top_sub span {
        margin: 0 4px !important;
    }
    .footer_top_sub select {
        width: 120px;
    }
    .footer_btm_box .text_box .btm_text.mobile {
        font-size: 12px;
    }
    .footer_part2 {
        padding: 10px;
    }
    
    .main_centent1_sub1 .title_box p,
    .learning_data_box .title_box p {
        font-size: 20px;
    }
}