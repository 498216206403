@import url('./fonts/fonts.css');



p {
  margin: 0;
  padding: 0;
}
button {
  margin: 0;
  padding: 0;
  border: none;
  cursor: pointer;
}
input, select, textarea {
  margin: 0;
  border: 1px solid var(--Grey_D, #DDD);
}

.App {
  text-align: center;
  overflow-x: hidden;
}
.default_box {
  width: 100%;
  padding-top: 248px;
  display: flex;
  justify-content: center;
}
.default_box.banner {
  padding-top: 188px;
}
.default_box.main {
  padding-top: 348px;
  padding-bottom: 100px
}


/* @keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} */

.loading {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 1000000;
  display: none;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
}
.loading.show {
  display: flex;
}
.loading-spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left: 4px solid #3498db; /* 원하는 색상으로 변경하세요 */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: rotate 1s linear infinite;
}