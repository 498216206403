@import url('../fonts/fonts.css');
/* Quick menu */

.quick_bar_1 {
    display: flex;
    width: 72px;
    flex-direction: column;
    position: fixed;
    height: 100%;
    z-index: 2000;
    top: 0;
    right: 0;

    border-left: 1px solid var(--Grey_C, #CCC);
    background: var(--White, #FFF);
}
.quick_menu_box .quick_login_btn.login {
    border-bottom: 1px solid var(--Grey_D, #DDD);
    background: var(--Grey_F1, #F1F1F1);
    padding: 20px 0;
}
.quick_menu_box .quick_login_btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 12px 0;
    gap: 4px;

    color: var(--Grey_6, #666);
    font-size: 12px;
    line-height: normal;
    cursor: pointer;
}
.quick_menu_box .quick_login_btn img {
    width: 20px;
    height: 20px;
}
.quick_menu_box .quick_login_btn.sub:hover {
    color: white;
    background: #3B4894;
}
.quick_arrow {
    position: absolute;
    top: 100px;
    left: -28px;
    width: 28px;
    height: 32px;
    padding: 10px;

    border-radius: 100px 0px 0px 100px;
    background: url(../assets/images/arrow-left-white.svg) no-repeat center center #666;
    cursor: pointer;;
}
.quick_arrow._2 {
    background: url(../assets/images/arrow-right-white.svg) no-repeat center center #666;   
}

.quick_bar_2 {
    display: flex;
    width: 200px;
    padding: 20px;
    flex-direction: column;
    position: fixed;
    gap: 20px;
    height: 100%;
    z-index: 2000;
    top: 0;
    right: 0;

    border-left: 1px solid var(--Grey_C, #CCC);
    background: var(--White, #FFF);
}
.quick_bar_2 .quick_logo_box {
    display: flex;
}
.quick_bar_2 .quick_logo_box img {
    width: 69px;
    height: 48px;
}
.quick_bar_2 .quick_login_input {
    display: flex;
    flex-direction: column;
    gap: 4px;
}
.quick_bar_2 .quick_login_input_box {
    border-radius: 8px;
    border: 1px solid var(--Grey_6, #666);
    display: flex;
    flex-direction: column;
    overflow: hidden;
}
.quick_bar_2 .quick_login_input_box input {
    border: none;
    border-radius: 0;
    padding: 12px;
}
.quick_bar_2 .quick_login_input_box .quick_id_input {
    border-bottom: 1px solid var(--Grey_C, #CCC);
}
.quick_bar_2 .quick_myclass_btn {
    border-radius: 4px;
    background: var(--Primary, #3B4894);
    display: flex;
    height: 40px;
    justify-content: center;
    align-items: center;
    width: 100%;
    color: var(--White, #FFF);
    font-size: 16px;
    font-weight: 700;
    line-height: normal;
    gap: 8px;
}
.quick_bar_2 .quick_mybook_box {
    display: flex;
    padding: 12px;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    position:relative;
    border-radius: 8px;
    border: 1px solid var(--Grey_6, #666);
}

.quick_mybook_box .title {
    color: var(--Dark, #2F3444);
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    line-height: normal;
}
.quick_mybook_box .sub_title {
    color: var(--Grey_6, #666);
    font-size: 10px;
    font-weight: 400;
    line-height: normal;
}
.quick_mybook_box .sub_title.center{
    position:absolute;
    transform:translate(0%,-50%);
    top:calc(50% + 5px);
}
.quick_swiper_box {
    width: 100%;
    position: relative;
}
.quick_link_box {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    border: 1px solid var(--Grey_6, #666);
}
.quick_link_box .tap {
    text-align: left;
    width: 100%;
    padding: 12px;
    color: var(--Dark, #2F3444);
    font-weight: 700;
    line-height: normal;
    background: url(../assets/images/right-gray.svg) no-repeat center right 12px;
    background-size: 16px;
    cursor: pointer;
}
.quick_link_box .tap:hover {
    color: var(--Primary, #3B4894);
    background: url(../assets/images/quick-right-navy.svg) no-repeat center right 12px;
    background-size: 16px;
}
.quick_login_test {
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.quick_login_test .quick_login_teacher {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 12px;
    border-radius: 8px;
    border: 1px solid var(--Grey_6, #666);
}
.quick_login_teacher .name {
    color: var(--Dark, #2F3444);
    font-size: 16px;
    font-weight: 700;
    line-height: normal;
}
.quick_login_teacher .school {
    color: var(--Grey_6, #666);
    font-size: 12px;
    line-height: normal;
}
.quick_login_teacher button {
    border-radius: 4px;
    background: var(--Grey_C, #CCC);
    display: flex;
    width: 80px;
    height: 32px;
    justify-content: center;
    align-items: center;

    color: var(--White, #FFF);
    line-height: 20px; /* 142.857% */
    margin: 8px 0;
}
.quick_login_teacher .my_set {
    display: flex;
    align-items: center;
    color: var(--Dark, #2F3444);
    line-height: 20px; /* 142.857% */
    cursor: pointer;
}
.quick_login_teacher .my_set img {
    width: 18px;
    height: auto;
}

/* //Quick menu */

/* header */

.header {
    background-color: white;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    top: 0;
    z-index: 10;
    border-bottom: 1px solid #ddd;
    box-shadow: 0px 10px 20px 0px rgba(153, 153, 153, 0.10);
}
.header_top_pop {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 60px;
    background: var(--Primary, #3B4894);
}
.header_top_pop .content {
    max-width: 1920px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
    position: relative;
}
.header_top_pop .content.point {
    cursor:pointer;
}
.header_top_pop .content img.close {
    position: absolute;
    left:calc(50% + 580px);
    top: calc(50% - 10px);
    cursor: pointer;
}
.header_top_pop .content .title {
    color: var(--Primary, #3B4894);
    font-weight: 700;
    line-height: normal;

    background: var(--Secondary, #F0CE12);
    display: flex;
    padding: 4px 8px;
    justify-content: center;
    align-items: center;
}
.header_top_pop .content .sub_title {
    color: var(--White, #FFF);
    font-size: 20px;
    font-weight: 700;
    line-height: normal;
}
.header_part1 {
    height: 48px;
    width: 100%;
    border-bottom: 1px solid var(--Grey_E, #EEE);
    display: flex;
    align-items: center;
    justify-content: center;
}
.header_top {
    width: 100%;
    max-width: 1200px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.header_top_sub {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.sub_tap_name {
    color: var(--Dark, #2F3444);
    line-height: 20px;
    cursor: pointer;
}
.sub_tap_name.hv:hover {
    color: #3B4894;
    font-weight: 700;
}
.sub_tap_name.btn {
    border-radius: 100px;
    background: var(--Primary, #3B4894);
    padding: 8px 20px;
    color: var(--White, #FFF);
    font-family: 'Pretendard';
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-left: 16px;
}
.header_top_sub.right .header_youtube,
.header_top_sub.right .header_naver {
    border: 1px solid #ddd;
    border-radius: 100px;
}
.header_top_sub.right .header_youtube:hover,
.header_top_sub.right .header_naver:hover {
    border: 1px solid #3B4894;
}
.header_top_sub .fav_btn {
    display: flex;
    gap: 8px;
    cursor: pointer;
    width: 105px;
}
.header_part2 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.header_part2_sub {
    height: 120px;
    max-width: 1200px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.header_part2 .header_title_box {
    display: flex;
    align-items: center;
    gap: 12px;
    cursor: pointer;
}
.header_title_box .title {
    color: var(--Primary, #3B4894);
    font-size: 44px;
    font-weight: 800;
    line-height: normal;
    font-family: 'NanumSquare_ac';
}
.header_title_box .sub_title_box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
}
.header_title_box .sub_title_box .sub_title {
    color: var(--Primary, #3B4894);
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    font-family: 'NanumSquare_ac';
}
.header_title_box .sub_title_box .sub_title.sm {
    font-size: 16px;
    font-weight: 700;
}
.header img {
    cursor: pointer;
}

.header_part3 {
    width: 100%;
    background: var(--White, #FFF);
    display: flex;
    justify-content: center;
}
.header_part3_sub {
    max-width: 1200px;
    width: 100%;
    height: 80px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;   
}
.header_part3_cate_box {
    display: flex;
    align-items: center;
    height: 64px;
    gap: 4px;
    width: 100%;
    position: relative;
}
.header_part3_cate_box.two{
    display: flex;
    gap: 0;
}
.header_part3_cate_box .tap {
    height: 64px;
    max-width: 160px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px 20px 0px 0px;

    color: var(--Dark, #2F3444);
    font-size: 24px;
    font-weight: 700;
    line-height: normal;
    cursor: pointer;
}
.header_part3_cate_box .tap.language.active {
    background: url(../assets/images/check-navy.svg) no-repeat center left 31px #FFE8F0 ;
}
.header_part3_cate_box .tap.society.active {
    background: url(../assets/images/check-navy.svg) no-repeat center left 31px #DCF4FF ;
}
.header_part3_cate_box .tap.art.active {
    background: url(../assets/images/check-navy.svg) no-repeat center left 31px #FFF5DB ;
}

.header_part3_cate_box .tap.language {
    background-color: #FFE8F0 ;
}
.header_part3_cate_box .tap.society {
    background-color: #DCF4FF ;
}
.header_part3_cate_box .tap.art {
    background-color: #FFF5DB ;
}

.header_part3_cate_box .tap.language:hover {
    background: url(../assets/images/check-navy.svg) no-repeat center left 31px #FFE8F0 ;
}
.header_part3_cate_box .tap.society:hover {
    background: url(../assets/images/check-navy.svg) no-repeat center left 31px #DCF4FF ;
}
.header_part3_cate_box .tap.art:hover {
    background: url(../assets/images/check-navy.svg) no-repeat center left 31px #FFF5DB ;
}


.header_part3_cate_box .tap.two {
    margin:0 4px;
    height: 48px;
    color: var(--Dark, #2F3444);
    font-size: 20px;
    font-weight: 700;
    line-height: normal;
    padding: 12px 0;
    position: relative;
    width:160px;
}
.header_part3_cate_box .tap.two:hover {
    background-color:#3B4894;
    border-radius:48px;color:#fff;
    
}
.header_part3_cate_box .tap.two.active {
    background-color:#3B4894;
    border-radius:48px;color:#fff;
    
}

.header_cate_hover_menu {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    top: calc(100% + 40px);
    left: 0;
    z-index: 1;
}
.header_cate_hover_menu.banner {
    top: calc(100% + 10px);
}
.header_cate_hover_menu.scroll {
    top: calc(100% + 124px);
}
.header_cate_hover_menu.clear {
    top: calc(100% + 94px);
}
.header_cate_hover_menu .cate_bar_box {
    display: flex;
    justify-content: center;
    
    padding-top: 16px;
    width: 100%;
    background: white;
    height: 326px;
    z-index: 9;
    box-shadow: inset 0px 10px 20px 0px rgba(153, 153, 153, 0.10);
}
.header_cate_hover_menu .cate_bar_box .cate_bar {
    width: 100%;
    max-width: 1200px;

}
.header_cate_hover_menu .cate_bar_box .cate_bar .class {
    display: flex;
    flex-direction: column;
    padding: 40px 0;
    gap: 20px;
    border-right: 1px solid var(--Grey_E, #EEE);
    color: var(--Dark, #2F3444);
    font-size: 20px;
    font-weight: 700;
    line-height: normal;
    width: 100%;
    max-width: 200px;
}
.header_cate_hover_menu .cate_bar_box .cate_bar .class p {
    text-align: left;
    width: 100%;
    cursor: pointer;
    padding-left: 40px;
}
.header_cate_hover_menu .cate_bar_box .cate_bar .class .middle.active {
    background: url(../assets/images/arrow-right-navy.svg) no-repeat center right 40px;
    background-size: 25px;
    color: var(--Primary, #3B4894);
}
.header_cate_hover_menu .cate_bar_box .cate_bar .class .high.active {
    background: url(../assets/images/arrow-right-navy.svg) no-repeat center right 40px;
    background-size: 25px;
    color: var(--Primary, #3B4894);
}
.header_cate_hover_menu .cate_bar_box .cate_bar .list {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 40px 40px 0 40px;
    gap: 40px;
}
.header_cate_hover_menu .cate_bar_box .cate_bar .list.middle.hide {
    display: none;
}
.header_cate_hover_menu .cate_bar_box .cate_bar .list.high.show {
    display: flex !important;
}
.header_cate_hover_menu .cate_bar_box .cate_bar .list .exit_box {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}
.header_cate_hover_menu .cate_bar_box .cate_bar .list .exit_box span {
    background: var(--Grey_9, #999);
    display: flex;
    width: 32px;
    height: 32px;
    justify-content: center;
    align-items: center;
}
.header_cate_hover_menu .cate_bar_box .cate_bar .list .box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}
.header_cate_hover_menu .cate_bar_box .cate_bar .list .box .sub_box {
    display: flex;
    align-items: center;
    gap: 12px;
    width: 100%;
}
.header_cate_hover_menu .cate_bar_box .cate_bar .list .box .sub_box p {
    display: flex;
    max-width: 120px;
    width: 100%;
    padding: 12px 20px;
    justify-content: center;
    align-items: center;

    border-radius: 8px;
    background: var(--Grey_F1, #F1F1F1);

    color: var(--Dark, #2F3444);
    font-size: 16px;
    line-height: normal;
    cursor: pointer;
}

.header_cate_hover_menu .cate_bar_box .cate_bar .list .box .sub_box p:hover {
    background: var(--Primary, #3B4894);
    color: var(--White, #FFF);
    font-size: 16px;
    font-weight: 700;
}
.header_cate_hover_menu .cate_bar_box .cate_bar .list .box .sub_box .set {
    padding: 12px 9px;
}
.header_cate_hover_menu .cate_bar_box .cate_bar .list .box .title {
    color: var(--Dark, #2F3444);
    font-size: 20px;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 12px;
}
.mobile{display:none}
.cate_bar{position:relative}
.cate_bar .menu_info{position:relative;padding:40px;}

.cate_bar .menu1.show{display:block;visibility:visible}
.cate_bar .menu1.hide{display:none !important;visibility:hidden}
.cate_bar .menu2.show{display:block;visibility:visible}
.cate_bar .menu2.hide{display:none !important;visibility:hidden}


.cate_bar .menu1.hide:empty{
    display: none;
}


.menu_info {text-align:left;}
.menu_info .box-menu1{position:absolute;left:200px;top:0;border-left:1px solid #F1F1F1;padding:40px 40px 32px 40px;}
.menu_info .btn-menu1{margin-bottom:20px;font-size:20px;font-weight:700;width:120px;text-align:left;}
.menu_info .btn-menu1.active{color:#3B4894;background:url(../assets/images/arrow-right-navy.svg) no-repeat center right 0 / 25px;}
.menu_info .btn-menu2{color:#2F3444;font-size:20px;font-weight:700;display:block;margin-bottom:12px}
.menu_info .btn-menu3{font-size:16px;min-width:120px;padding:0 20px; height:43px;border-radius:8px;line-height:43px;background-color:#f1f1f1;margin-left:12px;}
.menu_info .btn-menu3:first-child{margin-left:0}
.menu_info .btn-menu3:hover{color:#fff;font-weight:700;background-color:#3B4894}
.menu_info .scroll-menu2{margin-bottom:40px;overflow:hidden;overflow-x:auto;width:940px;}
.menu_info .scroll-menu2 div{width:max-content}
.exit_box{position:absolute;bottom:0;right:0;background-color:#999}

.back_blind {
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    opacity: 0.5;
    background: var(--Black, #000);
    position: fixed;
    z-index: 9;
}
/* /header */


/* footer */

.footer {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    border-top: 1px solid var(--Dark, #2F3444);
    background: var(--White, #FFF);
}
.footer_part1 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 64px;
    border-bottom: 1px solid var(--Grey_D, #DDD);
}
.footer_part1 .footer_top {
    max-width: 1200px;
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.footer_top_sub {
    display: flex;
    align-items: center;
    gap: 20px;
}
.footer_top_sub p {
    color: var(--Grey_6, #666);
    line-height: 20px;
    cursor: pointer;
}
.footer_top_sub select {
    padding-left: 12px;
    width: 200px;
    height: 40px;
    appearance: none;
    background: url(../assets/images/Down_Arrow_5.svg) no-repeat center right 8px;
    cursor: pointer;
}
.footer_part2 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 206px;
}
.footer_part2 .footer_btm {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 1200px;
    width: 100%;
}
.footer_btm_box {
    display: flex;
    gap: 40px;
}
.footer_btm_box .text_box,
.footer_btm_box .text_box div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}
.footer_btm_box .text_box .one {
    color: var(--Primary, #3B4894);
    font-size: 20px;
    font-weight: 800;
    line-height: normal;
}
.footer_btm_box .text_box div,
.footer_btm_box .text_box .two {
    color: var(--Grey_6, #666);
    font-family: Pretendard;
    line-height: 20px; /* 142.857% */
    margin: 12px 0;
    text-align: left;
}
.footer_btm_box .text_box .two {
   margin: 0;
}
.footer_btm_box img {
    width: 92px;
    height: 64px;
}

/* /footer */


.login_back {
    margin-top:40px;
    background: var(--Background, #F2F4F7);
    width: 100%;
    padding: 60px 0px;
    display: flex;
    justify-content: center;
}
.login_box {
    background: white;
    border-radius: 20px;
    display: flex;
    max-width: 500px;
    width: 100%;
    padding: 40px;
    flex-direction: column;
    align-items: center;
}
.login_box .title {
    color: var(--Dark, #2F3444);
    font-family: Pretendard;
    font-size: 32px;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 32px;
}
.login_box .sub_title {
    color: var(--Dark, #2F3444);
    font-family: Pretendard;
    line-height: 20px; /* 142.857% */
    margin-bottom: 8px;
}
.login_input_box {
    width: 100%;
    display: flex;
    flex-direction: column;
}
.login_input_box .input_pw {
    margin-top: 12px;
}
.login_input_box .checkbox,
.quick_login_input .checkbox{
    display: flex;
    align-items: center;
    gap: 8px;
    color: var(--Dark, #2F3444);
    font-size: 12px;
    line-height: normal;
    margin-top: 12px;
    margin-bottom: 32px;
}
.quick_login_input .checkbox {
    margin: 8px 0;
}


.login_input_box input[type="checkbox"],
.quick_login_input input[type="checkbox"] {
    appearance: none;
    border-radius: 4px;
    width: 18px;
    height: 18px;
    border: 1px solid var(--Grey_C, #ccc);
    background: var(--White, #FFF);
    vertical-align: middle;
    margin:-2px 7px 0 0
}
.login_input_box input[type="checkbox"]:checked,
.quick_login_input input[type="checkbox"]:checked{
    background-image: url(../assets/images/checked1.svg);
    background-size: 20px 20px;
    background-position: 50%;
    background-repeat: no-repeat;
    border-color: transparent;
}

.login_box .login_btn,
.quick_login_input .login_btn {
    border-radius: 4px;
    background: var(--Primary, #3B4894);
    display: flex;
    height: 48px;
    justify-content: center;
    align-items: center;
    width: 100%;

    color: var(--White, #FFF);
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 700;
    line-height: normal;
}
.quick_login_input .login_btn {
    font-size: 14px;
    height: 35px;
}
.login_box .join_btn {
    border-radius: 4px;
    border: 1px solid var(--Primary, #3B4894);
    background: var(--White, #FFF);
    display: flex;
    height: 48px;
    justify-content: center;
    align-items: center;
    width: 100%;

    color: var(--Primary, #3B4894);
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 700;
    line-height: normal;
}
.login_box .sub_text {
    color: var(--Dark, #2F3444);
    line-height: 20px; /* 142.857% */
    margin: 32px 0 8px 0;
}
.login_help_box {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--Dark, #2F3444);
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
    margin-top: 12px;
}
.login_help_box p {
    cursor: pointer;
}
.login_help_box p:hover {
    color: #3B4894;
}
.sns_login_box {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin: 32px 0;
}
.sns_login_box .kakao {
    width: 32px;
    height: 32px;
    border-radius: 100px;
    background: #F9E000 url(../assets/images/kakao.svg) no-repeat center;
    cursor: pointer;
}
.sns_login_box .naver {
    width: 32px;
    height: 32px;
    border-radius: 100px;
    background: #2DB400 url(../assets/images/naver.svg) no-repeat center;
    cursor: pointer;
}


.login_box .title_box {
    padding-bottom: 20px;
    margin-bottom: 32px;
    width:100%;
    border-bottom:1px solid #ddd;
}
.login_box .title_box.register .title {
    margin-bottom: 0;
}
.login_box .title_box.register {
    padding: 0;
    margin-bottom: 32px;
}
.login_box .title_box .title {
    margin-bottom: 8px;
}
.title_box .sub_title {
    color: var(--Dark, #2F3444);
    font-family: Pretendard;
    line-height: 20px; /* 142.857% */
}
.register_terms_box {
    width: 100%;
}
.register_terms_box_part1 {
    width: 100%;
    display: flex;
    align-items: flex-start;
    gap: 8px;
}
.terms_box_sub_text label {
    color: var(--Dark, #2F3444);
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 700;
    line-height: 16px;
}
.register_terms_box_part1 .terms_box_sub_text {
    max-width: 392px;
    width: 100%;
    color: var(--Dark, #2F3444);
    line-height: 20px; /* 142.857% */
    text-align: left;
}
.register_terms_box_part1 .terms_box_sub_text div {
    margin-top: 8px;
}
.register_terms_box input[type="checkbox"] {
    appearance: none;
    border-radius: 4px;
    width: 18px;
    height: 18px;
    border: 1px solid var(--Grey_C, #ccc);
    background: var(--White, #FFF);
}
.register_terms_box input[type="checkbox"]:checked{
    background-image: url(../assets/images/checked1.svg);
    background-size: 20px 20px;
    background-position: 50%;
    background-repeat: no-repeat;
    border-color: transparent;
}
.register_terms_box_part2 {
    border-radius: 12px;
    border: 1px solid var(--Grey_D, #DDD);
    display: flex;
    padding: 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
    margin-top: 12px;
    position: relative;
}
.register_terms_box_part2 .agree_chk {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.agree_chk .agree_chk_sub {
    display: flex;
    align-items: center;
    color: var(--Dark, #2F3444);
    font-size: 14px;
    font-weight: 400;
    line-height: 14px; /* 142.857% */
    gap: 8px;
}
.agree_chk button {
    border-radius: 100px;
    border: 1px solid var(--Grey_C, #CCC);
    background: var(--Grey_F1, #F1F1F1);

    display: flex;
    padding: 8px 20px;
    justify-content: center;
    align-items: center;

    color: var(--Dark, #2F3444);
    font-size: 12px;
    line-height: normal;
    height: 30px;
}
.login_box .login_btn.none {
    background: var(--Grey_C, #CCC);
}
.login_box .login_btn.none.active {
    background: var(--Primary, #3B4894);
}
.login_help_box.register {
    margin-top: 32px;
}
.error.register {
    color: red;
    margin-top: 8px;
    font-size: 12px;
}
.register_info_box {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    gap: 32px;
}
.register_info_box .mb_info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding: 20px;
    gap: 4px;

    border-radius: 12px;
    border: 1px solid var(--Grey_D, #DDD);
    background: var(--Grey_F1, #F1F1F1);
}
.register_info_box .mb_info .name {
    color: var(--Dark, #2F3444);
    font-size: 24px;
    font-weight: 700;
    line-height: normal;
}
.register_info_box .mb_info .name .gender {
    color: var(--Dark, #2F3444);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}
.register_info_box .mb_info .number {
    color: var(--Dark, #2F3444);
    font-size: 16px;
    line-height: normal;
}
.register_input_box {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    gap: 12px;
}
.register_input_box .comment {
    text-align: left;

    color: var(--Dark, #2F3444);
    font-size: 14px;
    line-height: 20px; /* 142.857% */
}
.register_input_box .comment span {
    font-weight: 700;
    text-decoration-line: underline;
}
.register_input_box .input_on {
    width: 100%;
    position: relative;
}
.register_input_box .input_on input {
    position: relative;
}
.register_input_box .input_on input:focus {
    border: 1px solid var(--Primary, #3B4894);
}
.register_input_box .input_on img {
    position: absolute;
    right: 12px;
    top: calc(50% - 8px);
}
.register_input_box .input_on .input_view_img {
    top: calc(50% - 10px);
    cursor: pointer;
}
.register_input_box .input_on .input_view_img.move {
    top: calc(50% - 10px);
    right: 30px;
}
.register_input_box .input_on .input_view_img.set {
    top: calc(50% - 18px);
}
.register_input_box .input_on .input_name {
    position: absolute;
    color: var(--Dark, #2F3444);
    font-size: 10px;
    font-weight: 400;
    line-height: normal;
    top: -6px;
    left: 10px;
    background-color: #FFF;
    padding: 0 8px;
}
.register_input_box .input_on .input_name span {
    color: var(--Red, #F00);
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.err_message {
    display: none;
    text-align: left;
    color: var(--Red, #F00);
    font-size: 12px;
    line-height: normal;
}
.err_message.on {
    margin-top: 4px;
    display: block;
}
.login_box .title_box.help {
    display: flex;
    width: 100%;
    padding: 0;
}
.login_box .title_box.help .title {
    padding-bottom: 20px;
    width: 100%;
    margin: 0;

    color: var(--Grey_C, #CCC);
    font-weight: 700;
    line-height: normal;
    cursor: pointer;
}
.login_box .title_box.help .title.active {
    color: var(--Dark, #2F3444);
    position: relative;
}
.login_box .title_box.help .title.active::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: var(--Dark, #2F3444);
}
.register_terms_box.help {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 32px;
}
.register_terms_box.help .info_box {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 12px;
}
.register_terms_box.help .info_box .info_box_sub {
    width: 100%;
    display: flex;
    align-items: center;
}
.register_terms_box.help .info_box .info_box_sub p {
    max-width: 100px;
    width: 100%;
    text-align: left;
    color: var(--Dark, #2F3444);
    line-height: 20px; /* 142.857% */
}
.register_terms_box.help .info_box .info_box_sub .short {
    max-width: 60px;
    width: 100%;
}
.register_terms_box.help .info_box .info_box_sub .phone_num {
    display: flex;
    align-items: center;
    gap: 8px;
}
.register_terms_box.help .info_box .info_box_sub .phone_num h4 {
    color: var(--Grey_9, #999);
    font-size: 20px;
    font-weight: 700;
    line-height: normal;
}
.login_help_sch_check {
    display: flex;
    width: 100%;
    gap: 20px;
}
.login_help_sch_check.active,
.title_box.help.active {
    display: none;
}
.login_help_sch_check .radio_box {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
}
.login_help_sch_check input {
    color: var(--Dark, #2F3444);
    line-height: 20px; /* 142.857% */
    appearance: none;
    background: url(../assets/images/radio-unchecked.svg);
    width: 20px;
    height: 20px;
    border: none;
    cursor: pointer;
}
.login_help_sch_check input:checked {
    background: url(../assets/images//radio-checked.svg);
}
.login_help_sch_check label {
    cursor: pointer;
}
.register_terms_box.help .info_box .login_btn.pw_help {
    max-width: 320px;
    width: 100%;
    float: right;
}
.register_terms_box.help .info_box .login_btn.pw_help.short {
    max-width: 360px;
}
.register_terms_box.help .info_box .chk_number_box {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 4px;
}
.register_terms_box.help .info_box .chk_number_box input {
    width: calc(100% - 61px);
}
.register_terms_box.help .info_box .chk_number_box .login_btn.chk_number {
    height: 40px;
    width: 57px;
    padding: 8px 16px;
    font-size: 14px;
}
.help_id_sch_none {
    padding: 40px;
    color: var(--Dark, #2F3444);
    text-align: center;
    font-size: 20px;
    line-height: normal;
    margin: 0 auto;
}
.register_terms_box.help .info_box .info_box_sub .phone_num .at {
    font-weight: 400;
    color: var(--Dark, #2F3444);
    font-size: 14px;
}
.register_terms_box.help .info_box .info_box_sub .phone_num .email_input {
    width: 60%;
}
.register_terms_box.help .info_box .info_box_sub .phone_num .email_input.two {
    width: 40%;
}
.title.pw_change {
    text-align: center;
    width: 100%;
    margin: 0;
}
.pw_change_box {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.pw_change_box .pw_change_title {
    color: var(--Dark, #2F3444);
    line-height: 20px; /* 142.857% */
    margin-bottom: 12px;
}












.main_move_top {
    display: flex;
    align-items: center;
    justify-content: center;
    filter: drop-shadow(4px 4px 15px rgba(0, 0, 0, 0.25));
    width: 48px;
    height: 48px;
    background: #FFF;
    border-radius: 100px;
    cursor: pointer;
    position: fixed;
    bottom: 20%;
    right: 15%;
    z-index: 2001;

    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s ease, visibility 0.5s ease;

}
.main_move_top.show {
    opacity: 1;
    visibility: visible;
}
.main_box {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding-top: 100px;
    padding-bottom: 100px;
    position: relative;
}
.main_part {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top:30px;
}
.main_box_sub {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 1200px;
    width: 100%;
}
.main_swiper_box {
    width: 100%;
    position: relative;
}
.main_swiper1 .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.main_swiper1 .swiper-slide img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.custom-pagination {
    width: 125px;
    height: 28px;
    position:absolute;
    left: unset;
    right: 21px;
    bottom: 19px;
    display: flex;
    justify-content:center;
    align-items:center;
    color:#fff;
    z-index:2;
    border-radius: 100px;
    background: rgba(0, 0, 0, 0.50);
    padding: 4px 8px;
    gap: 4px;

}
.main_swiper_box .custom-pagination .progress__bar {
    position:relative;
    width:40px;
    height:4px;
    background: rgba(255, 255, 255, 0.20);
    border-radius: 100px;
}
.main_swiper_box .custom-pagination .progress__fill {
    position:absolute;
    inset:0;
    display:block;
    background: #D9D9D9;
    border-radius: 100px;
}
.custom-pagination strong {
    width: 18px;
}
.custom-pagination .current,
.custom-pagination .total {
    color:white;
}
.custom-pagination img {
    width: 20px;
    height: auto;
    cursor: pointer;
}




.paging_box {
    width: 100%;
    border-right: 1px solid rgb(0 0 0 / 18%);
  }
  .paging_box .pos-r {
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  
  /* 슬라이드 커스텀 페이징 */
  .custom-pagination-current {
    color: #000;
    left: 10px;
    top: 50%;
    position: absolute;
    transform: translate(0px, -50%);
  }
  .custom-pagination-total {
    color: #000;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translate(0px, -50%);
  }
  
  /* 슬라이드 프로그레스 바 */
  .swiper-progress-bar {
    position: absolute;
    display: block;
    z-index: 1;
    height: 30px;
    width: calc(100% - 100px);
    top: 0px;
    left: 50%;
    transform: translate(-50%, 0%);
  }
  .swiper-progress-bar .bar {
    position: absolute;
    width: 100%;
    height: 2px;
    background: rgb(0 0 0 / 20%);
    clear: both;
    opacity: 0;
    bottom: 0;
    left: 0;
    right: 0;
    top: 50%;
    transform: translate(0px, -50%);
  }
  .swiper-progress-bar .bar:after {
    position: absolute;
    top: 0;
    left: 0;
    background: #000;
    height: 100%;
    width: 0;
    content: "";
    transition: 0.01s height linear;
  }
  
  /* 슬라이드 프로그레스 바 에니메이션 */
  .swiper-progress-bar.active .bar {
    opacity: 1;
  }
  .swiper-progress-bar.animate .bar:after {
    transition: width linear;
    transition-delay: unset;
    width: 100%;
    transition-duration: 4s;
  }


/* .pagination_container {
    display: flex;
    align-items: center;
    position: absolute;
    right: 21px;
    bottom: 19px;

    width: 120px;
    height: 28px;
    border-radius: 100px;
    background: rgba(0, 0, 0, 0.50);
    z-index: 2;
    color: white;
} */

/* .pagination_container img {
    width: 20px;
    height: auto;
    position: absolute;
    right: 8px;
    top: calc(50% - 10px);
    cursor: pointer;
}
.pagination_container .swiper-pagination-progressbar {
    width: 40px;
    top: calc(50% - 2px);
    left: 28px;

    border-radius: 2px;
    background: rgba(255, 255, 255, 0.20);
}
.pagination_container .swiper-pagination-progressbar-fill {
    background: #d9d9d9;
    border-radius: 2px !important;
}

.pagination_container .current {
    position: absolute;
    left: 8px;
    font-weight: 700;
    font-size: 12px;
}
.pagination_container .total {
    position: absolute;
    right: 32px;
    font-size: 12px;
} */




.main_swiper1_next, .main_swiper1_prev,
.quick_swiper1_next, .quick_swiper1_prev {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    border: 1px solid #DDDDDD;
    width: 32px;
    height: 32px;
    border-radius: 100px;

    fill: var(--White, #FFF);
    stroke-width: 1px;
    stroke: var(--Grey_D, #DDD);
    opacity: 0.8;
    filter: drop-shadow(4px 4px 15px rgba(0, 0, 0, 0.15));

    position: absolute;
    z-index: 5;
    top: calc(50% - 16px);
}
.quick_swiper1_next.hide, .quick_swiper1_prev.hide {opacity:0;}

.main_swiper1_next {
    right: -10px;
}
.main_swiper1_prev {
    left: -10px;
}
.quick_swiper1_next, .quick_swiper1_prev {
    width: 24px;
    height: 24px;
    top: calc(50% - 12px);
}
.quick_swiper1_next {
    right: 0px;
}
.quick_swiper1_prev {
    left: 0;
}
.quick_swiper1_next img,
.quick_swiper1_prev img {
    width: 14px;
    height: auto;
}

.main_content1 {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 80px 0;
    gap: 40px;
}
.main_content1 .line {
    width: 1px;
    flex-shrink: 0;
    align-self: stretch;
    background: var(--Grey_E, #EEE);
}
.main_content1 .main_centent1_sub1 {
    display: flex;
    flex-direction: column;
    max-width: 675px;
    width: 100%;
}
.main_centent1_sub1 .title_box,
.learning_data_box .title_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}
.main_centent1_sub1 .title_box p,
.learning_data_box .title_box p {
    color: var(--Dark, #2F3444);
    font-size: 24px;
    font-weight: 700;
    line-height: normal;
}
.main_centent1_sub1 .title_box button,
.learning_data_box .title_box button ,
.update_box .title_box button {
    display: flex;
    width: 88px;
    height: 32px;
    padding: 0px 12px;
    justify-content: space-between;
    align-items: center;
    border-radius: 100px;
    border: 1px solid var(--Grey_D, #DDD);

    color: var(--Dark, #2F3444);
    line-height: 20px; /* 142.857% */
    background: url(../assets/images/right-gray.svg) no-repeat center right 12px;
}
.main_centent1_sub1 .title_box button:hover,
.learning_data_box .title_box button:hover,
.update_box .title_box button:hover {
    font-weight: 700;
    color: #3B4894;
    border: 1px solid #3B4894;
    background: url(../assets/images/quick-right-navy.svg) no-repeat center right 12px;
}

.main_centent1_sub1 .content_box {
    display: flex;
    align-items: center;
    gap: 12px;
    width: 100%;
}
.main_centent1_sub1._2 {
    max-width: 444px;
}
.main_centent1_sub1._2 .comment {
    color: var(--Grey_6, #666);
    line-height: 20px; /* 142.857% */
    margin-bottom: 20px;
    text-align: left;
}
.subject_box .img_box {
    display: flex;
    height: 160px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
    max-width: 160px;
    width: 100%;
    border-radius: 8px;
    cursor: pointer;
    padding: 25px;
}
.subject_box .img_box img{border-radius:8px 8px 0 0}
.content_box .subject_box,
.subject_box.swiper {
    display: flex;
    flex-direction: column;
    gap: 12px;
    max-width: 160px;
    width: 100%;
    border: 1px solid #ffffff00;
}
.content_box .subject_box:hover,
.subject_box.swiper:hover {
    border-radius: 8px;
    border: 1px solid var(--Primary, #3B4894);
}
.subject_box .img_box{position:relative;margin-top:-1px}

.subject_box .comment {
    color: var(--Dark, #2F3444);
    font-weight: 700;
    line-height: normal;
    
}
.subject_box .img_box .comment{
    position:absolute;
    bottom:20px;
}

.subject_box .subject {
    display: flex;
    align-items: center;
    gap: 4px;
    cursor: pointer;
    padding: 12px;
}
.subject_box .subject .name1 {
    display: flex;
    padding: 4px 8px;
    justify-content: center;
    align-items: center;
    border-radius: 100px;

    color: var(--Dark, #2F3444);
    font-size: 12px;
    font-weight: 700;
    line-height: normal;
}
.subject_box .color0{background-color:#FED091}
.subject_box .color1{background-color:#FFE6A4}
.subject_box .color2{background-color:#CFD4EA}
.subject_box .color3{background-color:#FEE5DE}

.subject_box .subject .name2 {
    color: var(--Dark, #2F3444);
    font-size: 16px;
    font-weight: 700;
    line-height: normal;
}
.main_centent1_sub1._2 .shortcut_box{
    display: flex;
    align-items: center;
    gap: 12px;
}
.shortcut_box .shortcut {
    border-radius: 8px;
    display: flex;
    max-width: 140px;
    width: 100%;
    height: auto;
    padding: 12px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    border: 1px solid #ffffff00;
}
.shortcut_box .shortcut:hover {
    border: 1px solid var(--Primary, #3B4894);

}
.shortcut_box .text1 {
    color: var(--Dark, #2F3444);
    font-size: 16px;
    font-weight: 700;
    line-height: normal;
}
.shortcut_box .text2 {
    color: var(--Dark, #2F3444);
    font-weight: 400;
    line-height: 20px; /* 142.857% */
}

.main_content2 {
    width: 100%;
    display: flex;
    justify-content: center;
}
.learning_data_box {
    width: 100%;
    max-width: 1200px;
    display: flex;
    padding: 40px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 32px;
    border-radius: 20px;
    border: 1px solid var(--Grey_D, #DDD);
}
.learning_data .title_box {
    width: 100%;
}
.learning_data .title_box p {
    display: flex;
    align-items: center;
    gap: 12px;
}
.learning_data_box .learning_data {
    width: 100%;
}
.learning_data .post_list {
    width: 100%;
    display: flex;
    gap: 20px;
}
.learning_data .post_list .post_box,
.post_box.swiper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    max-width: 208px;
    width: 100%;
    cursor: pointer;
    border: 1px solid #ffffff00;
    border-radius: 8px;
}
.learning_data .post_list .post_box:hover,
.post_box.swiper:hover {
    border: 1px solid var(--Primary, #3B4894);
}
.learning_data .post_list .post_back,
.post_box.swiper .post_back {
    border-radius: 4px;
    background: var(--Grey_F1, #F1F1F1);
    aspect-ratio: 1/1;
    max-width: 208px;
    width: 100%;
    max-height: 191px;
    height: 100%;
}
.post_box.swiper .post_back.book {
    max-height: 270px;
    aspect-ratio: 1 / 1.3;
    overflow: hidden;
}
.post_box.swiper .post_back.book img {
    width: 100%;
}
.learning_data.book .post_box {
    height: 365px;
}
.learning_data.book .post_list .post_back.book {
    aspect-ratio: 220/270;
    max-width: 220px;
    width: 100%;
    max-height: 270px;
    height: 100%;
    overflow: hidden;
}
.learning_data .post_list .post_box .post_title,
.post_box.swiper .post_title {
    color: var(--Dark, #2F3444);
    font-size: 16px;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 8px;
    text-align: left;
    height: 52px;
    padding: 12px 12px 0 12px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
}
.learning_data .post_list .post_box .post_tag,
.post_box.swiper .post_tag {
    display: flex;
    flex-wrap: wrap;
    color: var(--Grey_6, #666);
    line-height: 20px; /* 142.857% */
    padding: 0 12px 12px 12px;
    text-align:left;
    height: 42px;
    overflow: hidden;
}
.learning_data .post_list .post_box .post_tag p,
.post_box.swiper .post_tag p {
    padding-right: 5px;
}
.learning_data_box .line {
    background: #EEEEEE;
    width: 100%;
    height: 1px;
}
.learning_data .post_list .post_box .subject,
.post_box.swiper .subject {
    display: flex;
    align-items: center;
    gap: 4px;
    margin-top: auto;

    padding: 0 12px 12px 12px;
}
.learning_data .post_list .post_box .subject .name1,
.post_box.swiper .subject .name1 {
    display: flex;
    padding: 4px 8px;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    color: var(--Dark, #2F3444);
    font-size: 12px;
    font-weight: 700;
    line-height: normal;
}
.learning_data .post_list .post_box .subject .name2,
.post_box.swiper .subject .name2 {
    color: var(--Dark, #2F3444);
    font-size: 16px;
    font-weight: 700;
    line-height: normal;
}


.main_content3 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 80px 0;
}
.main_content3 .bottom_other_box {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}
.bottom_other_box .event_box {
    display: flex;
    gap: 10px;
    height: 200px;
    border-radius: 10px;
    max-width: 360px;
    width: 100%;
    cursor: pointer;
    border: 1px solid #fff;
}
.bottom_other_box .event_box:hover {
    border: 1px solid #ff584e;
}
.bottom_other_box .event_box .img_box {
    display: flex;
    align-items: flex-end;
}

.bottom_other_box .event_box img {
    border-radius:10px;
    width:100%;
}
.bottom_other_box .event_box .img_box img {
    width: 91px;
    height: 100px;
}
.bottom_other_box .event_box .title_box {
    max-width: 210px;
    width: 100%;
    text-align: left;
}
.bottom_other_box .event_box .title_box .title {
    font-size: 24px;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 4px;
}
.bottom_other_box .event_box .title_box .comment {
    line-height: 20px; /* 142.857% */
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
}
.bottom_other_box .update_box {
    border-radius: 8px;
    border: 1px solid var(--Grey_D, #DDD);
    max-width: 440px;

    display: flex;
    width: 100%;
    height: 200px;
    padding: 20px 20px 8px 20px;
    flex-direction: column;
    align-items: center;
}
.update_box .title_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 12px;
}
.update_box .title_box .title {
    color: var(--Dark, #2F3444);
    font-size: 20px;
    font-weight: 700;
    line-height: normal;
}
.update_box .title_box .navi_btn_box {
    display: flex;
    align-items: center;
    height: 100%;
    position: relative;
    width: 57px;
}

.update_box .title_box .navi_btn_box .navi_btn {
    cursor: pointer;
    width: 28px;
    height: 28px;
    position: absolute;
    z-index: 1;
}
.update_box .title_box .navi_btn_box .navi_btn.update_next {
    right: 1px;
}
.update_box .title_box .navi_btn_box .navi_btn.update_prev {
    left: 1px;
}

.update_box .title_box .navi_btn_box .navi_btn:active,
.update_box .title_box .navi_btn_box .navi_btn:hover {
    background: var(--Grey_E, #EEE);
}
.update_box .title_box .navi_btn_box .navi_btn.update_prev,
.update_box .title_box .navi_btn_box .navi_btn.update_next {
    border: 1px solid var(--Grey_D, #DDD);
}


.update_box .title_box .navi_btn_box .navi_btn.update_prev {
    background: url(../assets/images/update-left.svg) no-repeat center center;
    background-size: 20px;
}
.update_box .title_box .navi_btn_box .navi_btn.update_next {
    background: url(../assets/images/update-right.svg) no-repeat center center;
    background-size: 20px;
}
.update_box .title_box .navi_btn_box .navi_btn.update_prev:hover {
    border: 1px solid var(--Primary, #3B4894);
    background: url(../assets/images/arrow-left-navy.svg) no-repeat center center;
    background-size: 20px;
    z-index: 2;
}
.update_box .title_box .navi_btn_box .navi_btn.update_next:hover {
    border: 1px solid var(--Primary, #3B4894);
    background: url(../assets/images/arrow-right-navy.svg) no-repeat center center;
    background-size: 20px;
    z-index: 2;
}
/* .update_box .title_box .navi_btn_box .navi_btn.active {
    background: #fff;
} */
.update_box .title_box .navi_btn_box .navi_btn img {
    width: 14px;
    height: 14px;
}
.update_box .title_box .navi_btn_box .line {
    width: 1px;
    background: #ddd;
    height: 100%;
    transition: background-color .2s;
}
.update_box .title_box .navi_btn_box:hover .line {
    background: var(--Primary, #3B4894);
}

.update_box .title_box .navi_btn_box .navi_btn.update_next.swiper-button-disabled {
    background: url(../assets/images/update-right.svg) no-repeat center center #eee;
}
.update_box .title_box .navi_btn_box .navi_btn.update_prev.swiper-button-disabled {
    background: url(../assets/images/update-left.svg) no-repeat center center #eee;
}
.update_box .title_box .navi_btn_box .navi_btn.update_next.swiper-button-disabled:hover {
    border: 1px solid #ddd;
    background: url(../assets/images/update-right.svg) no-repeat center center #eee;
}
.update_box .title_box .navi_btn_box .navi_btn.update_prev.swiper-button-disabled:hover {
    border: 1px solid #ddd;
    background: url(../assets/images/update-left.svg) no-repeat center center #eee;
}


.slide_post {
    font-size:16px !important;
    width: 100%;
    text-align: left;
    padding: 12px 0;
    color: var(--Dark, #2F3444);
    line-height: 20px; /* 142.857% */
    border-bottom: 1px solid var(--Grey_E, #EEE);

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
    display:block;
    position:relative;
}
.slide_post:hover {
    color: var(--Primary, #3B4894);
    font-weight: 700;
}

.slide_post button{position:absolute;right:0;top:7px;}

.main_content4 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.main_content4 .service_box {
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 1200px;
    height: 230px;
    border: 1px solid var(--Grey_D, #DDD);
    border-radius: 20px;

}
.main_content4 .service_box .service_tap {
    padding: 32px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 340px;
    width: 100%;
}
.main_content4 .service_box .service_tap a {
    width: 100%;
    border-radius: 8px;
    border: 1px solid var(--Grey_D, #DDD);
    padding: 12px 20px;
    text-align: left;
    display:block;
    font-size:16px;
    background: url(../assets/images/service-btn-right.svg) no-repeat center right 20px;
    margin-bottom:8px;
}
.main_content4 .service_box .service_tap a:last-child {
    margin-bottom:20px;
}
.main_content4 .service_box .service_tap a:hover {
    border: 1px solid var(--Primary, #3B4894);
    color: #3B4894;
    background: url(../assets/images/arrow-right-navy.svg) no-repeat center right 20px;
}
.main_content4 .service_box .service_tap .service_call {
    display: flex;
    align-items: center;
    gap: 8px;
}
.main_content4 .service_box .service_tap .service_call div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.main_content4 .service_box .service_tap .service_call .title {
    color: var(--Dark, #2F3444);
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
}
.main_content4 .service_box .service_tap .service_call .call_num {
    color: var(--Dark, #2F3444);
    font-size: 24px;
    font-weight: 700;
    line-height: normal;
}
.main_content4 .service_box .service_tap.notice {
    max-width: 500px;
    width: 100%;
    padding: 32px 32px 10px 32px;
    height: 100%;
}
.main_content4 .service_box .service_tap.notice .title {
    color: var(--Dark, #2F3444);
    font-size: 20px;
    font-weight: 700;
    line-height: normal;
    cursor: pointer;
}
.main_content4 .service_box .service_tap.notice .notice_post_list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}
.service_tap.notice .notice_post_list .notice_post {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 12px 0;
    border-bottom: 1px solid var(--Grey_E, #EEE);
    cursor: pointer;
}
.service_tap.notice .notice_post_list .notice_post:nth-last-child(1) {
    border: none;
}
.service_tap.notice .notice_post_list .notice_post .title {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    color: var(--Dark, #2F3444);
    line-height: 20px; /* 142.857% */
    max-width: 330px;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.service_tap.notice .notice_post_list .notice_post .date {
    color: var(--Grey_6, #666);
    font-weight: 300;
    line-height: normal;
}
.service_tap.notice .notice_post_list .notice_post:hover .title {
    color: #3B4894;
    font-weight: 700;
}
.main_content4 .service_box .service_tap.client {
    max-width: 180px;
}
.service_tap.client .title {
    color: var(--Dark, #2F3444);
    font-size: 20px;
    font-weight: 700;
    line-height: normal;
    /* cursor: pointer; */
}
.service_tap.client .link {
    color: var(--Dark, #2F3444);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    cursor: pointer;
}
.service_tap.client .link:hover {
    color: var(--Primary, #3B4894);
    font-weight: 700;
}