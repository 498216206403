@charset "utf-8";
@import url('../fonts/fonts.css');

* { box-sizing: border-box; }

*, html, body {
	font-family: 'Pretendard', 'NanumSquare_ac', "noto_sans_kr", sans-serif; 
	-webkit-text-size-adjust:none;
}
.pop_test_box {
    display: flex;
    gap: 10px;
}
.pop_test_btn {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: orange;
    color: white;
    border-radius: 100px;
    cursor: pointer;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

html {height:100%; overflow-y: scroll; -webkit-overflow-scrolling : touch; overflow-x: hidden; }
body {
    margin:0;
    padding:0;
    font-size:14px;
    font-weight: 400;
    height:100%;
    /* color:#000; */
    /* min-width: 1920px; */
}

html, body {width: 100%; height:100%;  }
/* html, body { background-color: #fff; width: 100%; height:100%;  } */

html, h1, h2, h3, h4, h5, h6, form, fieldset, img {margin:0;padding:0;border:0}
h1, h2, h3, h4, h5, h6 {font-weight:700; font-size:15px;}
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {display:block}
#hd ul, nav ul, #ft ul {margin:0;padding:0;list-style:none}
legend {position:absolute;margin:0;padding:0;font-size:0;line-height:0;text-indent:-9999em;overflow:hidden}
label, input, button, select, img {vertical-align:middle}
input, button {margin:0;padding:0;font-size:1em; outline: none;}
button {cursor:pointer; outline: none; border: none; background: #fff;}
textarea, select {font-size:1em; outline: none;}
select {margin:0}
p {margin:0;padding:0;word-break:keep-all;}
hr {display:none}
pre {overflow-x:scroll;font-size:1.1em}

a:link, a:visited {font-size:13px;font-weight: 500;color:#000;text-decoration:none}
a:hover, a:focus, a:active {color:#000;text-decoration:none}
ul {padding:0; margin:0; list-style:none;}
li {padding:0; margin:0; list-style:none;}

input[type="text"], input[type="password"] {
    border-radius: 4px;
    border: 1px solid var(--Grey_D, #DDD);
    background: var(--White, #FFF);
    padding: 0px 12px;
    width: 100%;
    height: 40px;
}

.shake {
    animation: shake 0.3s;
}

.shake::before {
    content: '';
    position: absolute;
    top: -1px; left: -1px; right: -1px; bottom: -1px;
    border-radius: 12px;
    border: 1px solid rgba(255, 0, 0, 0.5);
    box-shadow: 0 0 10px 2px rgba(255, 0, 0, 0.5);
    transition: opacity 0.5s ease-out;
    opacity: 1;
    pointer-events: none;  /* 가상 요소가 클릭 이벤트를 막지 않도록 설정 */
}

.shake.fade-out::before {
    opacity: 0;
}

@keyframes shake {
    0% { transform: translateX(0); }
    25% { transform: translateX(-5px); }
    50% { transform: translateX(5px); }
    75% { transform: translateX(-5px); }
    100% { transform: translateX(0); }
}

.popup {
    display: none;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 9999;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.50);
    top: 0;
    left: 0;
}
.popup.show {
    display: flex;
    animation: popup-show 0.3s;
}
.exit_btn {
    background: url('../assets/images/exit-icon.svg') no-repeat center center;
    background-size: 24px;
    width: 48px;
    height: 48px;
}
.popup_container {
    max-width: 600px;
    width: 100%;
    max-height: 600px;
    height: 100%;
    background-color: #fff;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 8px;

    animation: popup-container-show 0.3s;
    overflow: hidden;
}
.popup_title_section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 20px;
    height: 64px;
    border-bottom: 1px solid #eee;
}

.popup_title {
    color: var(--Dark, #2F3444);
    font-size: 20px;
    font-weight: 700;
    line-height: normal;
}
.popup_btn {
    padding: 0 20px;
}

.popup_msg {
    font-size: 14px;
    max-height: 536px;
    height: 100%;
    font-weight: normal;
    color: #606060;
    padding: 0;
    text-align: left;
    word-break: unset !important;
    overflow: auto !important;
}
.popup_msg::-webkit-scrollbar {
    display: none;  /* Safari and Chrome */
}
.popup_btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.popup_default01 {
    background-color: #EFE7DE;
    padding: 35px 53px;
    border-radius: 10px;
}
.popup_text_box {
    text-align: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    white-space: pre-wrap;
}
.popup_btn_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
}
.popup_btn_box > .button_cancel {
    padding: 9px 52px;
    border-radius: 5px;
    border: 1px solid #000;
    background-color: #EFE7DE;
    font-weight: 700;
    font-size: 14px;
    line-height: 20.27px;
}
.popup_btn_box > .button_success {
    padding: 9px 52px;
    border-radius: 5px;
    border: 1px solid #231916;
    background-color: #231916;
    font-weight: 700;
    font-size: 14px;
    line-height: 20.27px;
    color: #fff;
}
.popup_btn_box02 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
}
.popup_btn_box02 > .button_cancel {
    width: 48%;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #000;
    background-color: #EFE7DE;
    font-weight: 700;
    font-size: 14px;
    line-height: 20.27px;
}
.popup_btn_box02 > .button_success {
    width: 48%;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #231916;
    background-color: #231916;
    font-weight: 700;
    font-size: 14px;
    line-height: 20.27px;
    color: #fff;
}
.popup_slide_container {
    width: 720px;
    height: 577px;
    margin-bottom: 70px;
}
.popup_slide_box {
    /* cursor: pointer; */
}
.popup_slide_img {
    cursor: pointer;
}
.popup_slide_img > img {
    width: 720px;
    height: 577px;
    object-fit: cover;
}
@keyframes popup-show {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@keyframes popup-container-show {
    from {
        opacity: 0;
        margin-top: -50px;
    }
    to {
        opacity: 1;
        margin-top: 0;
    }
}

.popup_title_section.register {
    border: none;
    height: 40px;
}
.popup_register {
    max-width: 400px;
    width: 100%;
    background-color: #fff;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 8px;

    animation: popup-container-show 0.3s;
    overflow: hidden;
}
.popup_register_comment_box {
    padding: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    white-space: pre-line;
}
.popup_register_comment_box .popup_message {
    color: var(--Dark, #2F3444);
    font-size: 20px;
    font-weight: 400;
    line-height: normal;
    white-space: pre-wrap;
}
.popup_register_comment_box .popup_message.id {
    font-weight: 700;
}
.popup_register_comment_box .popup_press,
.popup_register_comment_box .popup_close {
    border-radius: 8px;
    background: var(--Primary, #3B4894);
    display: flex;
    width: 164px;
    height: 48px;
    justify-content: center;
    align-items: center;
    margin-top: 40px;

    color: var(--White, #FFF);
    font-size: 16px;
    font-weight: 700;
    line-height: normal;
}
.popup_register_comment_box .popup_close {
    background: var(--Grey_C, #CCC);
}
.popup_btn_box_custom {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
}