
figure{    margin-inline-end: 0;
    margin-inline-start: 0;
    margin: 0;}
select::-ms-expand { display: none;}
select{-webkit-appearance:none;-moz-appearance:none; -o-appearance:none; appearance:none; background:url(../assets/images/chevron-down.svg) right 10px center no-repeat;}
.changbi table{border-spacing:0;border-collapse:collapse;}
.changbi{width:100%;;}

.quick_swiper_box .swiper-slide{cursor:pointer;}

.banner_pc{margin:0 auto;width:100%;height:60px;}
.banner_mobile{margin:0 auto;width:100%;height:60px;display:none}

.popup-main{border:1px solid #ccc;border-radius:8px;;z-index:20;background-color:#fff;position:fixed;left:50%;top:50%;transform:translate(-50%,-50%)}
.popup-main a{display:block;}
.popup-main img{border-radius:8px 8px 0 0}
.popup-main .popup-btns{height:40px;position:relative;padding:0 20px;text-align:left;line-height:40px;}
.popup-btns img{vertical-align: middle;margin:-1px 5px 0 0}
.popup-main button{font-size:12px;}
.popup-main button:last-child{position:absolute;right:20px;top:14px;}
/*component begin*/
.changbi input,.changbi  button{appearance: none;-moz-appearance: none;-o-appearance: none;border:none;-webkit-appearance: none;border-radius:5px;}
.changbi input::placeholder,.changbi textarea::placeholder{color:#b6b6b6}
/*button*/
.changbi .btn{width:100%;height:40px;color:#fff;background-color:#3b4894;}
/*checkbox*/
.changbi .check{position:relative;display:inline-block;margin-right:20px;height:18px;}
.changbi .check input{border:1px solid #ccc;width:18px;height:18px;vertical-align:top;border-radius:4px;background-color:#fff;position:relative;}
.changbi .check input:checked{border:1px solid #3B4894;background:#3B4894 url(../assets/images/icon_check.svg) no-repeat 4px center}
.changbi .check input:checked:before{color:#fff}
.changbi .check span{margin:1px 0 0 5px;display:inline-block;}
/*radio*/
.changbi .radio{position:relative;display:inline-block;margin-right:20px;height:18px;}
.changbi .radio input{border:1px solid #ccc;width:18px;height:18px;vertical-align:top;border-radius:18px;background-color:#fff;position:relative;}
.changbi .radio input:before{content:' ';font-size:7px;font-weight:700;position:absolute;left: 50%;top: 50%; transform: translate(-50%, -50%);color:#ccc;border: 0;background-color: #fff;border-radius: 20px;width: 8px;height: 8px;}
.changbi .radio input:checked{border:1px solid #3B4894;background-color:#3B4894}
.changbi .radio input:checked:before{color:#fff}
.changbi .radio span{margin:1px 0 0 5px;display:inline-block;}
.changbi .input-text, .changbi .input-phone,.changbi .input-email {height:40px;margin:6px 0;text-align:left;display:flex;width:100%;}
.changbi .input-text label, .changbi .input-phone label, .changbi .input-email label{width:100px;line-height:40px;}
/*label input text box*/
.changbi .input-text input{width:calc(100% - 100px);border:1px solid #ddd;border-radius:4px;padding:0 12px}
/*label input radio box*/
.changbi .input-phone input{width:calc(100% - 330px);border:1px solid #ddd;border-radius:4px;padding:0 12px}
.changbi .input-phone span{width:25px;text-align:center;line-height:38px;font-weight:700;font-size:20px;color:#999}
/*label input email box*/
.changbi .input-email div{display:flex;width:calc(100% - 100px);}
.changbi .input-email input{width:50%;border:1px solid #ddd;border-radius:4px;padding:0 12px}
.changbi .input-email span{width:25px;text-align:center;line-height:38px;}
/*label input auth box*/
.changbi .input-auth {position:relative;margin:6px 0;display:block;text-align:left;display:block;width:100%;}
.changbi .btn-auth{position:relative;float:right;width:calc(100% - 100px);right:0;top:0;height:40px;border-radius:4px;background-color:#3B4894;color:#fff;}
.input-auth .receive-auth{padding-top:30px;display:flex;width:100%;}
.receive-auth label{width:100px;line-height:59px;}
.receive-auth div{display:inline-block;width:calc(100% - 100px);}
.receive-auth input{border:1px solid #ddd;border-radius:4px;padding:0 12px;}
.receive-auth .enter-auth{display:flex;margin:8px 0 8px 0;position:relative;width:100%;}
.receive-auth .enter-auth input{width:calc(100% - 61px);padding:0 57px 0 12px}
.receive-auth .enter-auth span{position:absolute;right:70px;top:13px;font-size:12px;color:#3B4894}
.receive-auth .enter-auth button{width:57px;height:40px;color:#fff;background-color:#3B4894;margin-left:4px}
.changbi .input-auth small{color:#ff0000;font-size:12px;margin:0 0 0 100px}
/*label input pw box*/
.changbi .input-pw {display:block}
.changbi .input-pw label{display:block;text-align:left;width:100%;}
.changbi .input-pw input{width:100%;display:block;border:1px solid #ddd;border-radius:4px;height:40px;margin:6px 0;padding:0 12px;}
/*tab*/
.changbi .tab {display:flex;width:100%;}
.changbi .tab button{border-bottom:0 solid #fff;color:#ccc;padding:0 0px 20px 0px;font-size:32px;border-radius:0;font-weight:700;width:50%;}
.changbi .tab button.active{border-bottom:1px solid #2F3444;color:#2F3444}
/*404*/
.nopage-box {margin-top:80px}
.nopage-box p{font-size:32px;font-weight:700;color:#2F3444;}
/*modal 메세지*/
.modal{position:fixed;left:0;top:0;width:100%;height:100%;background:rgba(0,0,0,0.5);z-index:100}
.modal-box {display:inline-block;text-align:center;border-radius:8px;background-color:#fff;padding:32px;position:absolute;left:50%;top:50%;transform:translate(-50%,-50%);}
.modal-box a{display:block;position:absolute;right:0;top:0;width:40px;height:40px;color:#999;font-size:16px;line-height:38px;cursor:pointer;}
.modal-box p{font-size:20px;text-align:center;margin:32px 0}
.modal-box div{display:flex;margin:0 -4px;}
.modal-box button{margin:0 4px;height:48px;color:#fff;background-color:#ccc;border-radius:8px;width:100%;}
.modal-box button:last-child{background-color:#3B4894;}
/*component end*/
.popup-box{position:absolute;left:50%;top:50%;transform:translate(-50%,-50%)}
/*공통*/

.wrap{display:flex;width:100%;max-width:1200px;position:relative;padding:0 0;margin:120px auto 100px auto;text-align:left;}
.event .wrap{margin-top:80px}
.wrap .title{font-size:32px;font-weight:700;margin-bottom:12px}
.wrap .comment{color:#666}
.wrap .tab{display:flex;margin-top:32px}
.wrap .tab button{color:#999;font-size:16px;background-color:#F1F1F1;border:1px solid #fff;margin-left:-1px;z-index:1;line-height:50px;height:52px;font-weight:500}
.wrap .tab button.active{border:1px solid #3B4894;border-bottom:1px solid #fff;z-index:9;color:#3B4894;font-weight:700;background-color:#fff;}
.wrap .category {border-radius:12px;border:1px solid #ddd;padding:26px 32px;margin-bottom:32px}
.wrap .category label{height:36px;font-size:16px;margin-right:20px;width:30px;margin-top:8px;}
.wrap .category .tabs01 label{line-height:32px;}
.wrap .category .tabs02 label{line-height:32px;}
.wrap .category div{display:flex;margin:6px 0}
.wrap .category button{width:60px;color:#999;font-size:14px;background-color:#F1F1F1;border-right:1px solid #fff;z-index:1;line-height:34px;height:36px;font-weight:500;border-radius:0;}
.wrap .category button.active{z-index:9;color:#fff;font-weight:700;background-color:#3B4894;}
.faq .category{border:0;padding:0;display:flex;margin:0 -6px;padding:10px 0 32px 0}
.faq .category button{font-size:16px;margin:0 6px;border-radius:48px;height:48px;width:100%;background-color:#fff;color:#999;border:1px solid #ccc}
.faq .category button.active{z-index:9;color:#fff;font-weight:700;background-color:#3B4894;border:1px solid #3B4894}
.changbi .box{margin-left:60px;display:inline-block;width:calc(100% - 300px)}
.event .box{width:100%;margin:0;}
.agree_chk label{letter-spacing:-1px;}



/*아이디비번찾기*/
.find-box .input-radio{display:block;width:100%;text-align:left;margin:33px 0 32px 0;}
.find-box .btn{margin-top:26px}
.find-box .no-find{padding:40px;font-size:20px;font-weight:500;margin-top:32px;}
.reset-box p{font-size:32px;font-weight:700;color:#2F3444;margin-bottom:32px}
.reset-box .btn{margin-top:26px}
/*교육과정*/
.course{box-shadow: inset 0px 10px 20px 0px #9999991A; width:100%;margin-top:60px;border-bottom:4px solid #eee}
.course-top {width:100%;max-width:1200px;position:relative;padding:0 0;margin:60px auto;display:inline-block;}
.course-title {display:flex;position:relative}
.course-title p{text-align:left;margin-left:40px}
.course-title h1{font-size:36px;font-weight:700;margin:10px 0 10px 0}
.course-title .my {width:97px;height:36px;background-color:#fff;color:#3B4894;border:1px solid #ccc;line-height:34px}
.course-title .my img{vertical-align: middle;margin:-3px 4px 0 0;}
.course-title .view {position:absolute;bottom:0px;border:0;padding:0;left:200px}
.course-title .view a{text-align:center;vertical-align:top;border:1px solid #3B4894;background-color:#E9ECFF;font-size:16px;font-weight:700;width:180px;height:48px;line-height:46px;margin-right:8px;display:inline-block;border-radius:4px}
.course-title .view a img{vertical-align: middle;margin:-3px 5px 0 0;}
.course-title .view button{border:1px solid #3B4894;background-color:#E9ECFF;font-size:16px;font-weight:700;width:180px;height:48px;line-height:46px;margin-right:8px}
.course-title .view button img{vertical-align: middle;margin:-3px 5px 0 0;}
.course-button{position:absolute;right:0;bottom:0px;width:300px;margin-right:-4px}
.course-button button{width:calc(50% - 8px);height:36px;background-color:#fff;color:#3B4894;border:1px solid #ccc;line-height:34px;margin:8px 4px 0 4px ;font-weight:700}
.course-button button.first{width:calc(100% - 8px);}
.course-button a{font-size:14px;border-radius:5px;display:inline-block;width:calc(50% - 8px);height:36px;background-color:#fff;color:#3B4894;border:1px solid #ccc;line-height:34px;margin:8px 4px 0 4px ;font-weight:700}
.course-button a:first-child{width:calc(100% - 8px);}

.course-detail{width:100%;max-width:1200px;position:relative;padding:0 0;margin:60px auto;display:flex}
.course-menu{width:240px;}
.course-menu dl {list-style:none; margin:0; padding:0; counter-reset:list-number;border:1px solid #ddd;border-radius:12px;width:100%;text-align:left;padding:24px 24px 14px 24px;margin-bottom:20px}
.course-menu dt {font-size:20px;font-weight:700;display:block;border-bottom:1px solid #eee;padding-bottom:16px;margin-bottom:6px;}
.course-menu dd {display:block;width:100%;margin:0;padding:10px 0;background:url(../assets/images/chevron-down.svg) no-repeat right 0px top 10px / 20px;}

.course-menu dd span{font-weight:500;font-size:16px;cursor:pointer;width:90%;display:inline-block}
.course-menu dd.active {background-image:url(../assets/images/chevron-up.svg);}
.course-menu dd.active span{font-weight:700;}
.course-menu dd.active:before {font-weight:700;}
.course-menu ul{counter-reset:list-number1;margin-left:5px;padding-top:4px}
.course-menu li {padding:8px 0 0 0;cursor:pointer;}
.course-menu li:before{margin-right:5px}
.course-menu li.active {font-weight:700;color:#3B4894}
.course-menu li.active:before {font-weight:700;}
.course-menu button{font-size:20px;font-weight:700;border:1px solid #ddd;border-radius:12px;width:100%;height:72px;text-align:left;padding:0 24px;background:url(../assets/images/right-gray.svg) no-repeat right 20px center / 20px;}
.course-menu select{display:none}
.course-menu dl.course-common dt{cursor:pointer;}
.course-menu dl.course-common dd{cursor:pointer;background:transparent}
.course-menu dl.course-common dd.active span{font-weight:bold}

.course-tab{width:calc(100% - 300px);margin-left:60px;}
.course-tab h2{font-size:32px;font-weight:700;text-align:left;}
.course-tab .tab{margin-top:20px;}
.course-tab .tab button{height:52px;line-height:52px;width:25%;background-color:#f1f1f1;font-size:16px;font-weight:500;color:#3B4894;border:1px solid #fff;border-bottom:1px solid #f1f1f1;border-top:1px solid #f1f1f1;}
.course-tab .tab button.active{font-size:16px;font-weight:700;border:1px solid #3B4894;border-bottom:1px solid #fff;background-color:#fff;color:#3B4894;}
.course-tag {text-align:left;margin-top:32px;}
.course-tag li{display:inline-block;height:36px;border:1px solid #ccc;padding:0 12px;line-height:34px;border-radius:8px;margin-right:8px;cursor:pointer}
.course-tag li.active{border:1px solid #3B4894;color:#fff;font-weight:700;background-color:#3B4894}
/*수업 혁신*/
.inno-menu{width:240px;display:inline-block;}
.inno-menu select{display:none}
.inno-menu dl {list-style:none; margin:0; padding:0; border:1px solid #ddd;border-radius:12px;width:100%;text-align:left;padding:24px 24px 14px 24px;margin-bottom:20px}
.inno-menu dt {font-size:20px;font-weight:700;display:block;border-bottom:1px solid #eee;padding-bottom:16px;margin-bottom:6px;background:url(../assets/images/chevron-down.svg) no-repeat right 0px top 3px / 20px;cursor:pointer}
.inno-menu dd {display:block;width:100%;margin:0;padding:10px 0;cursor:pointer;}
.inno-menu dd span{font-size:16px;font-weight:500}
.inno-menu dd span.active{color: #3B4894; font-weight: 700;}
.inno-menu dd.active span{color:#3B4894;font-weight:700}
.inno-menu ul{margin-left:5px;padding-top:4px;display:none}
.inno-menu ul.active{display:block;}
.inno-menu li {padding:8px 0 0 0;font-size:14px;font-weight:500}
.inno-menu li.active {font-weight:700;color:#3B4894}
.inno-banner {margin-top:-10px}
.inno-banner div{display:flex;margin:0 -10px}
.inno-banner a{margin:10px;display:block}
.inno-banner img{width:100%;margin:0;border-radius:12px;}
.dict-main .dict1 {display:flex;margin:0 -10px}
/*.dict-main .dict1 div{background-color:#CFD6FF;border-radius:12px;width:50%;margin:0 10px;height:320px;padding:32px;align-items: center;display:flex}*/
.dict-main .dict1 div{border-radius:12px;width:100%;margin:0 10px;align-items: center;display:flex}
.dict-main .dict1 div img{border-radius:12px;}

.dict-main .dict1 h1{font-size:32px;margin-bottom:20px;}
.dict-main .dict1 p{font-size:14px;margin:0px 0 0 0;line-height:18px}
.dict-main .dict2 {display:flex;margin:20px -6px;}
.dict-main .dict2 h2{font-size:20px;margin:8px 0}
.dict-main .dict2 a{font-size:14px}
.dict-main .dict2 button{background-color:#DCF4FF;border-radius:12px;width:33.33%;margin:0 6px;padding:20px 0}
.dict-main .dict2 button:first-child{background-color:#FFE8F0;}
.dict-main .dict2 button:last-child{background-color:#FFF5DB;}
/*리스트0*/
.list {text-align:left;}
.list dl{width:100%;}
.list dt{height:52px;background-color:#f1f1f1;display:block;margin:0;padding:0 21px;border-bottom:1px solid #ccc;}
.list dt .check{margin-top:6px}
.list dt .check span{margin-left:15px}
.list dt button{font-weight:700;height:36px;padding:0 20px;border:1px solid #ccc;border-radius:4px;color:#3B4894;margin:8px 12px 0 0}
.list dt img{vertical-align: top;margin:-2px 3px 0 0;}
.list .search {float:right;position:relative;margin:8px 0 0 0}
.list .search input{width:300px;height:36px;border:1px solid #ddd;border-radius:4px;padding:0 50px 0 10px}
.list .search button{width:26px;height:36px;background:transparent;border:0;position:absolute;right:1px;top:1px;margin:0;text-align:center;padding:0;}
.list .search button.text-remove{right:28px;width:20px;}
.list dd{border-bottom:1px solid #ddd;display:block;margin:0;position:relative;height:56px;padding-left:21px;line-height:56px}
.list dd:hover{background-color:#f1f1f1;}
.list dd .check{margin-right:8px;margin-top:-2px;}
.list dd div{position:absolute;right:0;top:8px;}
.list dd div.pd{top:0;}
.list dd div.curr{top:0px;}
.list dd span{margin-left:8px;}
.list dd strong{font-weight:normal;margin-left:0px;width:calc(100% - 30px);display: -webkit-box;-webkit-line-clamp: 1;-webkit-box-orient: vertical;overflow: hidden;text-overflow: ellipsis;word-break: break-word;}
.changbi.pop._02._04 .list dd strong{margin-left:40px;width:calc(100% - 70px);}
.list dd img.cimg{position:absolute;left:8px;top:14px;}
.list dd button{margin-right:8px;background:transparent;}
.list dd button img{background:transparent;}
/*리스트1*/

.list-type1 {border-bottom:1px solid #ddd}
.list-type1 ul{margin:16px -16px}
.list-type1 li{width:calc(33.33% - 32px);display:inline-block;margin:16px;vertical-align: top;border:1px solid #fff;}
.list-type1 li:hover{border:1px solid #3B4894;border-radius:8px;}
.list-type1 p{margin:8px 0;position:relative}
.list-type1 small{color:#666;margin-right:10px}
.list-type1 h2{font-size:16px;font-weight:700;margin:8px 0}
.list-type1 .event {position:relative;text-align:right;font-size:12px}
.list-type1 .event small{float:left;margin-top:-2px;height:22px;line-height:22px;padding:0 8px;color:#fff;background-color:#3B4894;font-size:12px;font-weight:700;border-radius:22px}
.list-type1 img{border-radius:8px}
/*리스트4*/
.list-type4 {border-bottom:1px solid #ddd}
.list-type4 ul{margin:16px -8px}
.list-type4 li{width:calc(25% - 16px);display:inline-block;margin:16px 8px;vertical-align: top;border:1px solid #fff;}
.list-type4 li:hover{border:1px solid #3B4894;border-radius:8px;}
.list-type4 h2{font-size:16px;font-weight:700;margin:12px 12px 2px 12px}
.list-type4 p{margin:8px 12px}
.list-type4 small{margin-right:5px;color:#666;border-radius:22px;height:22px;padding:0 8px;line-height:22px;background-color:#ffe6a4;font-size:12px;font-weight:700;display:inline-block}
.list-type4 small.end{background-color:#000;color:#fff}
.list-type4 span{font-weight:700;font-size:16px}
.list-type4 img{border-radius:8px;}
/*리스트2*/
.list-type2 {margin:0;border-bottom:1px solid #ddd;}
.list-type2 .total{border-bottom:1px solid #ddd;height:40px;}
.list-type2 ul{padding-top:32px;margin:0 -10px}
.list-type2 li{width:calc(25% - 20px);display:inline-flex;margin:0 10px 32px 10px;border:1px solid #fff;}
.list-type2 li:hover{border:1px solid #3B4894;border-radius:8px;}
.list-type2 img{height:220px;display:inline-block;margin-right:0;border-radius:8px}
.list-type2 span {font-size:14px;line-height:20px;display:block;margin-bottom:8px;word-break: break-word;}
.list-type2 small {display:inline-block;color:#2F3444;font-size:12px;font-weight:700;padding:0 8px;height:22px;margin-right:4px;background-color:#FED091;border-radius:100px;line-height:22px;}
.list-type3 {display:flex;margin:32px -10px 0 -10px}
.list-type3 h2{font-size:24px;font-weight:700;height:60px;line-height:60px;padding:0 0 0 68px;border-radius:12px 12px 0 0}

.list-type3 ul{padding:32px 32px 8px 32px}
.list-type3 li {display:flex;margin-bottom:24px;border:1px solid #fff;}
.list-type3 li:hover{border:1px solid #3B4894;border-radius:8px;}
.list-type3 img {margin-right:12px;width:100px;height:100px;border-radius:8px;}
.list-type3 span {font-size:14px;line-height:20px;display:block;margin-bottom:8px;word-break: break-word;}
.list-type3 small {display:inline-block;color:#2F3444;font-size:12px;font-weight:700;padding:0 8px;height:22px;margin-right:4px;background-color:#FED091;border-radius:100px;line-height:22px;}
.list-type3 .type0{border:2px solid #FFE8F0;border-radius:12px;margin:0 10px;margin-bottom:20px;width:33.33%}
.list-type3 .type1{border:2px solid #DCF4FF;border-radius:12px;margin:0 10px;margin-bottom:20px;width:33.33%}
.list-type3 .type2{border:2px solid #FFF5DB;border-radius:12px;margin:0 10px;margin-bottom:20px;width:33.33%}
.list-type3 .scroll{overflow:hidden;overflow-y:auto;height:502px;}

.list-type3 .type0 h2{background:#FFE8F0 url(../assets/images/icon_inno1.svg) no-repeat 20px 12px / 36px}
.list-type3 .type1 h2{background:#DCF4FF url(../assets/images/icon_inno2.svg) no-repeat 20px 12px / 36px}
.list-type3 .type2 h2{background:#FFF5DB url(../assets/images/icon_inno3.svg) no-repeat 20px 12px / 36px}
.info-image{margin:26px 0 40px 0;border-radius:8px}
.info-title{padding:0 0;height:60px;line-height:60px;position:relative;font-size:16px;font-weight:700;border-bottom:1px solid #ccc;}
.info-title span{margin-lefT:5px;display:inline-block;}
.info-title small{display:inline-block;background-color:#FED091;padding:0 8px;height:22px;line-height:22px;font-size:12px;font-weight:700;border-radius:24px;margin-right:4px}
.info-title button{bordeR:1px solid #ccc;border-radius:4px;width:77px;height:36px;position:absolute;right:0;bottom:12px;}
.info-title img{margin-right:5px;width:20px;height:20px;}
.info-editor{padding:40px 0;font-weight:500;}
.offer-data {counter-reset:list-number;border:1px solid #ddd;border-radius:12px;padding:20px;}
.offer-data dt{display:block;font-size:16px;font-weight:700;margin-bottom:12px;}
.offer-data dd{text-align:left;display:block;border-bottom:1px solid #ddd;margin:0 0 0 20px;height:46px;line-height:46px;}
.offer-data dd:before{}
.offer-data dd:last-child{border-bottom:0;}
.offer-data button{float:right;width:52px;height:22px;color:#fff;margin-left:4px;font-size:12px;font-weight:700;margin-top:11px;}
.offer-data button.ZIP{background-color:#34AEE7}
.offer-data button.PDF{background-color:#B30B00}
.offer-data button.HWP{background-color:#00CCF2}
.info-button {text-align:center;margin-top:40px;}
.info-button button{width:120px;height:36px;border-radius:4px;border:1px solid #ccc;color:#3B4894;font-weight:700;font-size:14px;}
.list-button {padding:10px 0 10px 0}
.list-button button{margin:0 8px 0 0;border:1px solid #3B4894;border-radius:4px;height:44px;padding:0 20px;background-color:#E9ECFF;font-weight:700;font-size:16px;color:#3B4894}
.dictionary .list{margin-top:30px;}
.dictionary .tab button{color:#3B4894;font-size:16px;}
.dictionary .tab button strong{color:#3B4894;font-size:16px;}
.changbi .flex{display:flex !important;}
.changbi .show{display:block !important;}
.changbi .hide{display:none !important;}
.changbi .input-text.show, .changbi .input-phone.show,.changbi .input-email.show {display:flex !important;}
/*왼쪽메뉴*/
.etc-menu{width:240px;display:inline-block}
.etc-menu dl{width:100%;padding:8px 24px;border:1px solid #ddd;border-radius:12px;margin-top:0;}
.etc-menu select{display:none}
.etc-menu dt{display:none}
.etc-menu dd{border-bottom:1px solid #eee;font-size:20px;padding:16px 0;cursor:pointer;margin:0;padding-right:18px}
.etc-menu dd span{font-size:20px;}
.etc-menu dd:last-child{border-bottom:0;}
.etc-menu dd.active{color:#3B4894;font-weight:700;background:url(../assets/images/chevron-right.svg) no-repeat right center / 20px;}
/*상세*/
.view {border-top:1px solid #2F3444;padding-bottom:32px}
.view .sub{font-size:24px;font-weight:700;height:60px;padding-top:18px;margin-top:30px}
.view .sub div{float:right;}
.view .sub button{font-size:14px;display:inline-block;padding:0 14px;height:36px;line-height:36px;font-weight:700;color:#3B4894;border:1px solid #ccc;border-radius:4px;margin-left:8px;margin-top:-3px}
.view .sub button img{margin-right:5px;}
.view .info-editor{border-top:1px solid #ddd;border-bottom:1px solid #ddd}
.info-editor img{max-width:100%;}
/*팝업리스트*/
.changbi.pop{border:1px solid #fff;border-radius:8px;background-color:#fff;width:500px;text-align:left;margin:0 auto}
.changbi.pop h1{height:64px;border-bottom:1px solid #ddd;font-size:20px;font-weight:700;padding:0 0 0 20px;line-height:64px;position:relative}
.changbi.pop h1 button{position:absolute;right:8px;top:8px;}
.changbi.pop .form{padding:32px;}
.changbi.pop .select label{width:37px;font-weight:500;display:inline-block;height:40px;line-height:40px;}
.changbi.pop .select select{width:calc(100% - 37px);border:1px solid #ddd;border-radius:4px;height:40px;padding:0 10px}
.changbi.pop .tag label{width:80px;font-weight:500;display:inline-block;line-height:82px}
.changbi.pop .tag ul{width:calc(100% - 80px);display:inline-block}
.changbi.pop .tag li{width:100px;display:inline-block;margin:4px 6px}
.changbi.pop .tag button{border-radius:36px;height:36px;width:100%;background-color:#fff;color:#999;border:1px solid #999}
.changbi.pop .tag button.active{background-color:#3B4894;color:#fff;border:1px solid #3B4894}
.changbi.pop .select{margin:10px 0}
.changbi.pop .tag{margin:10px 0;display:flex;}
.changbi.pop .input{margin:10px 0;position:relative}
.changbi.pop .input input{width:100%;border:1px solid #ddd;border-radius:4px;height:40px;padding:0 10px}
.changbi.pop .input button{position:absolute;right:1px;top:1px;height:38px;width:38px;}
.changbi.pop .input button.text-remove{right:30px}

.changbi.pop .scroll{overflow:hidden;overflow-y:auto;height:220px;}
.scroll::-webkit-scrollbar {width: 4px;}
.scroll::-webkit-scrollbar-thumb {background-color: #3B4894}
.scroll::-webkit-scrollbar-track {background-color: #f1f1f1}

.changbi.pop .popup-btn{text-align:center;}
.changbi.pop .popup-btn button{border:0;border-radius:8px;background-color:#3B4894;color:#fff;font-size:16px;font-weight:700;width:164px;height:48px;margin:0 4px}
.changbi.pop .popup-btn button:first-child{background-color:#CCCCCC;}
.changbi.pop .list dd{position:relative}



.changbi.pop._02{width:800px;}
.changbi.pop._02 h1,.changbi.pop._03 h1{border-bottom:0;}
.changbi.pop._02 .form,.changbi.pop._03 .form{padding:0 20px 28px 20px;margin:0;}
.changbi.pop._02 .list {padding:0;margin:0;}
.changbi.pop._02 .list dl{padding:0;margin:0;}
.changbi.pop._02 .list dl.scroll{border:1px solid #ddd}
.changbi.pop._02 .list dt{height:36px;text-align:center;line-height:36px;position:relative;border:0;}
.changbi.pop._02 .list dt label{position:absolute;left:13px;top:4px;}
.changbi.pop._02 .list dd{height:48px;line-height:20px;padding:14px 13px 0 13px;}
.changbi.pop._02 .popup-btn{padding-top:8px;text-align:left;}
.changbi.pop._02 .popup-btn button{border:1px solid #ddd;border-radius:4px;background-color:#fff;color:#3B4894;font-size:14px;font-weight:700;width:auto;height:36px;margin:0 0 0 12px;padding:0 25px;}
.changbi.pop._02 .popup-btn button img{margin-right:4px}
.changbi.pop._03{width:600px;}
.changbi.pop._04{width:1000px;}
.changbi.pop._03 .list {padding:0;margin:0;border:0}
.changbi.pop._03 .list dl{padding:0;margin:0;}
.changbi.pop._03 .list dt{height:36px;text-align:center;line-height:36px;position:relative;border:0;border-bottom:1px solid #ddd;}
.changbi.pop._03 .list dt label{position:absolute;left:13px;top:4px;}
.changbi.pop._03 .list dd{height:48px;line-height:20px;padding:14px 13px 0 13px;border-bottom:1px solid #ddd;border-top:0;}
.changbi.pop._04 .form.flex{display:flex;margin:0 -10px}
.changbi.pop._04 .form.flex .list{margin:0 10px;width:33.33%;}
.changbi.pop .tag._02 label{line-height:42px}
.changbi.pop .radios label.t{width:77px;font-weight:500;display:inline-block;height:40px;line-height:40px;}
.changbi.pop .radios div{width:calc(100% - 77px);height:40px;display:inline-block;}
/*고객센터*/
.board{width:100%;max-width:1000px;margin:0 auto}
.board h1{font-size:32px;font-weight:700;margin:0 0 20px 0;position:relative}
.board.notice{margin-top:20px;}
.board.faq{margin-top:20px;}
.board tr:hover{background-color:#f1f1f1}
.list-faq dd:hover{background-color:#f1f1f1}

.board h1 small{padding:0 10px;height:22px;color:#fff;font-weight:700;font-size:12px;background-color:#3B4894;border-radius:22px;display:inline-block;line-height:22px;vertical-align:middle;margin:-5px 12px 0 0}
.board h1 small.end{background-color:#000}
.board table{border-spacing:0;border-collapse:collapse;width:100%;;margin-bottom:20px}
.board th{height:40px;background-color:#F1F1F1;text-align:center;border-bottom:1px solid #CCCCCC;font-weight:500;}
.board td{border-bottom:1px solid #eee;height:48px;padding:0 20px;font-size:16px;}
.board tr.notice{background-color:#FAFAFA}
.board td.f14{font-size:14px}
.board tr.notice td:first-child{position:relative;padding-left:66px;}
.board tr.notice td:first-child:before{content:'필독';font-size:0px;background:#f0c312 url(../assets/images/icon_notice.png) no-repeat center center / 37px 22px;border-radius:4px;width:37px;height:22px;text-align:center;display:block;position:absolute;left:20px;top:13px;}
.board td a{font-size:16px;}
.board.notice td a{display: -webkit-box;-webkit-line-clamp: 1;-webkit-box-orient: vertical;overflow: hidden;text-overflow: ellipsis;word-break: break-word;}
.board.view{margin-top:0px;border:0;}
.board th.text-left{text-align:left;padding-left:20px;}
.board .text-center{text-align:center}
.board table.type02 th{font-size:14px;}
.board table.type02 tr:hover{background-color:#f1f1f1;}
.board table.type02 td{font-size:14px;}
.board table.type02 td span{cursor:pointer}
.board .category button{min-width:120px;}
/*게시판 이전글다음글*/
.preview {padding-top:20px}
.preview dt{width:57px;border-bottom:1px solid #EEEEEE;height:44px;display:inline-block;margin-left:0px;line-height:44px;font-weight:500;font-size:14px;}
.preview dd{width:calc(100% - 57px);border-bottom:1px solid #EEEEEE;height:44px;display:inline-block;margin-left:0px;line-height:44px;font-size:14px;}
.preview dd a{font-size:14px;}
.preview .nodata{color:#999;font-size:14px}
/*상단 공지사항, 마이메뉴 */
.my-menu {padding:16px 0 8px 0}
.my-menu a{display:inline-block;width:168px;height:48px;line-height:48px;font-size:20px;font-weight:700;margin:0 4px}
.my-menu a.active, .my-menu a:hover{background-color:#3B4894;border-radius:48px;color:#fff;}
/*네비*/
.navi{border-top:1px solid #ddd;border-bottom:0px;height:40px;width:100%;box-shadow: inset 0px 10px 20px 0px #9999991A; }
.navi div{background:url(../assets/images/home_navi.svg) no-repeat left center / 20px;max-width:1200px;width:100%;text-align:left;margin:0 auto;height:40px;line-height:40px;}
.navi span{font-size:14px;margin:0 0 0 30px;background:url(../assets/images/right-gray.svg) no-repeat left center / 12px;padding-left:20px;}
.navi span.event_title{margin-left:10px;}
.navi strong{font-size:14px;margin:0 0 0 10px;background:url(../assets/images/right-gray.svg) no-repeat left center / 12px;padding-left:20px;}
/*faq*/
.list-faq {width:100%;}
.list-faq .dt-faq{border-bottom:1px solid #2F3444;height:64px;margin:0;padding:0;position:relative;padding-top:24px;}
.list-faq .dt-faq form{position:absolute;right:0;bottom:12px;}
.list-faq .dt-faq input{width:300px;height:40px;border-radius:4px;border:1px solid #ddd;padding:0 10px;}
.list-faq .dt-faq button{position:absolute;bottom:1px;right:2px;width:28px;height:38px;text-align:center;}
.list-faq .dt-faq button.text-remove{right:25px;}
.list-faq .dd-faq{border-bottom:1px solid #dddddd;height:67px;margin:0;padding:0px;}
.list-faq .h2-faq{background:url(../assets/images/chevron-down.svg) no-repeat right 20px center / 20px;height:67px;position:relative;padding:8px 23px 0 63px;line-height:25px;cursor:pointer}
.list-faq .dd-faq.open .h2-faq{background:#F4F6FF url(../assets/images/chevron-up.svg) no-repeat right 20px center / 20px;}
.list-faq .h2-faq:before{content:'Q';background-color:#CAD1F9;color:#3B4894;font-size:700;font-weight:16px;border-radius:32px;width:32px;height:32px;position:absolute;text-align:center;line-height:30px;top:18px;left:20px;}
.list-faq .h2-faq small{display:block;color:#666;font-size:14px;font-weight:500;}
.list-faq .h2-faq span{font-size:16px;font-weight:700;display:block;}
.list-faq .div-faq{display:none;line-height:20px;font-size:14px;padding:20px 20px 20px 70px;position:relative;background:url(../assets/images/icon_faq.svg) no-repeat 40px 20px / 20px;}
.list-faq .p-faq{border-top:1px solid #eee;margin-top:20px;padding-top:20px;color:#666}
.list-faq .a-faq{position:absolute;bottom:20px;right:20px;width:120px;height:40px;color:#fff;font-weight:700;border-radius:4px;background-color:#3B4894;text-align:center;line-height:40px;}
.list-faq dd.open {height:auto;border:1px solid #CAD1F9;margin-top:-1px}
.list-faq dd.open h2{background-color:#F4F6FF}
.list-faq dd.open div{display:block}
/*댓글*/
.comment h2{font-size:16px;font-weight:700;margin:20px 0 12px 0}
.comment input{width:100%;height:48px;border:1px solid #ddd;border-radius:4px;padding:0 10px;}
.comment h2 button{width:102px;height:36px;color:#fff;font-size:14px;font-weight:700;background-color:#3B4894;float:right;margin-top:12px}
.comment dt{border-bottom:1px solid #eee;font-weight:500;padding:60px 0 12px 0;margin-bottom:10px}
.comment dd{border-bottom:1px solid #eee;margin:0;padding:12px 0;position:relative}
.comment small{font-weight:500}
.comment p{font-size:16px;font-weight:500;padding:8px 0 0 0;}
.comment p.lock{background:url(../assets/images/icon_lock.svg) no-repeat 0 7px / 20px;padding-left:24px;}
.comment dd button{position:absolute;right:0;top:12px;font-size:12px;}
.comment .btn-regist{width:120px;height:36px;background-color:#3B4894;color:#fff;border-radius:4px;float:right;margin:12px 0 0 0}
/*모달 팝업*/
.modal_box {border-radius:8px;padding:32px;background-color:#fff;position:relative;min-width:400px;}
.modal_box p{padding:20px 0 40px 0;font-size:20px;font-weight:500;white-space: pre-wrap;}
.modal_box p button{position:absolute;right:8px;top:8px;}
.modal_box div{text-align:center;}
.modal_box div button{background-color:#ccc;width:160px;height:48px;line-height:48px;color:#fff;font-size:16px;font-weight:700;border-radius:8px;margin:0 4px;}
.modal_box div button:last-child{background-color:#3B4894;}


/*내 메뉴*/
.my {width:100%;max-width:1000px;margin:0 auto}

.my h1.title{position:relative;height:58px;width:100%;border-bottom:1px solid #2F3444;margin:0;}
.my h1.title.none{border-bottom:0;}
.my h1 button{position:absolute;right:0;top:0;padding:0 12px;height:36px;line-height:36px;border:1px solid #ccc;color:#3B4894;font-size:14px;font-weight:700;}
.my h1 button img{margin:-2px 3px 0 0}
.my ul{margin:0;padding:0;}
.my li{position:relative;display:flex;padding:20px;margin:0;border-bottom:1px solid #F2F4F7}
.my li a{display:flex}
.my li div{margin:15px 0 0 20px}
.my li span{font-size:14px;}
.my li h2{font-size:24px;font-weight:700;margin-top:7px;display: -webkit-box;-webkit-line-clamp: 1;-webkit-box-orient: vertical;overflow: hidden;text-overflow: ellipsis;word-break: break-word;margin-right:20px;}
.my li button{position:absolute;right:20px;top:50px;}
.my li button.add_plus{border:1px solid #3B4894;border-radius:4px;width:32px;height:32px;top:44px;right:10px;}
.my dl{margin:0;}
.my dt{background-color:#f1f1f1;height:44px;line-height:44px;display:flex;}
.my dt label{text-align:center}
.my .wall{width:100%;padding:0 12px}
.my .w120{width:164px;padding:0 12px;text-align:center}
.my .w100{width:124px;padding:0 12px;text-align:center}
.my dd{height:48px;line-height:48px;border-bottom:1px solid #ddd;margin:0;overflow:hidden}
.my dd div{display:flex}
.my dd .ing{color:#ff0000}

.my dd.active .wall{font-weight:700;color:#3b4894;}
.my dd.active {height:auto;overflow:unset}

.my .write{display:block;line-height:20px;padding:20px 20px 0 30px}
.my dd button{border:1px solid #ddd;border-radius:4px;background-color:#f1f1f1;padding:0 10px;height:36px;line-height:36px;margin-right:8px;margin-top:10px;}
.my .reply{display:block;line-height:20px;padding:20px 20px 20px 55px;background:url(../assets/images/icon_faq.svg) no-repeat 26px 20px / 20px;}
.my .reply span{background:url(../assets/images/icon_reply.png) no-repeat 0 0 / 37px 22px;padding:2px 0 0 47px;height:22px;display:block;margin:0 0 12px 0;}
.my .reply p{margin:0;padding:0;}
.qna {width:800px;margin:0 auto}
.qna select{border:1px solid #ddd;width:400px;height:40px;padding:0 10px;border-radius:4px;display:block;margin:32px 0 0 0}
.qna input[type=text]{border:1px solid #ddd;width:100%;height:40px;padding:0 10px;border-radius:4px;display:block;margin:12px 0 0 0}
.qna textarea{border:1px solid #ddd;width:100%;height:40px;padding:10px;border-radius:4px;display:block;margin:12px 0 0 0;height:200px;}
.qna .file{margin:12px 0 0 0;}
.qna .file label{position:relative;width:120px;height:40px;display:inline-block}
.qna .file input{width:100%;height:40px;position:absolute;opacity:0;top:0;left:0;}
.qna .file button{width:100%;height:40px;line-height:40px;color:#fff;font-weight:700;background-color:#3B4894}
.qna .file span{margin:0 0 0 8px}
.qna .add_file{margin:12px 0 0 0}
.qna .add_file span{display:inline-block;height:40px;padding:0 12px;border:1px solid #ddd;background-color:#f1f1f1;border-radius:4px;line-height:40px;margin:0 8px 0 0}
.qna .add_file button{background:transparent;vertical-align: middle;margin:-6px 0 0 7px;}
.write_button {text-align:center;margin-top:32px;}
.write_button button{width:200px;height:48px;background-color:#ccc;color:#fff;border-radius:4px;margin:0 4px;font-size:16px;font-weight:700;}
.write_button button:last-child{background-color:#3B4894}
.pop .my ul{margin-bottom:20px;border-top:1px solid #F2F4F7;padding-top:20px}
.pop .my li{padding:20px 10px}
.pop .my li.active{background-color:#f1f1f1}
.pop .my li h2{font-size:20px;}
.board-button{padding-bottom:12px}
.board-button button{bordeR:1px solid #ccc;border-radius:4px;height:36px;padding:0 10px;margin-right:8px}
.board-button button img{margin:0 3px 0 0}
.info {max-width:800px;width:100%;margin:0 auto}
.info h1{position:relative;font-size:32px;font-weight:700;border-bottom:1px solid #ccc;padding:0 0 20px 0}
.info h1 button{position:absolute;right:0;top:0;padding:0 12px;border-radius:4px;border:1px solid #ccc;height:33px;color:#3B4894;font-size:14px;}
.info h2{font-size:24px;font-weight:700;padding:0 0 12px 0;margin:32px 0 0 0}
.info table{width:100%;}
.info .basic {border-top:1px solid #ddd;}
.info .marketing{border-top:1px solid #ddd;}
.info th{background-color:#f1f1f1;}
.info .basic th,.info .marketing th{height:56px;border-bottom:1px solid #ddd;padding:0 20px 0 20px;width:120px;}
.info td{height:56px;background-color:#ffffff;border-bottom:1px solid #ddd;padding:0 20px 0 20px;}
.info input[type=text] {max-width:400px;width:100%;border:1px solid #ddd;color:#999;margin-right:4px}
.info input[type=text]:read-only{background-color:#f1f1f1;color:#999;}
.info td span{margin-right:10px;}
.info td small{margin-left:5px;color:#666;font-size:12px;}
.info td button{height:40px;width:100px;color:#fff;border-radius:4px;font-weight:700;background-color:#3B4894;}
.info th.star::after{content:'*';color:#ff0000;}
.info .sns th{height:44px;text-align:center}
.info .sns td{height:44px}
.info .sns td.text-center{text-align:center}
.info .sns td button{height:32px;width:80px;background-color:#ccc}
.info .marketing th{height:72px;line-height:25px;}
.info .marketing td{height:72px;}
.info .marketing strong{display:block;margin-bottom:8px}
.info .no td{color:#ccc} 
.info .sns .no button{background-color:#3B4894}
.info-btn{text-align:center;width:400px;margin:32px auto}
.info-btn button{height:48px;width:100%;border-radius:4px;background-color:#3B4894;color:#fff;font-size:16px;font-weight:700}
.pop.school{width:600px;}
.school-box{padding: 20px}
.school-box div {position:relative}
.school-box div input{width:100%;height:40px;padding:0 10px;border-radius:4px;border:1px solid #ddd;}
.school-box div button{position:absolute;right:1px;top:1px;width:38px;height:38px;}
.school-box div small{display:block;margin:5px 0 0 0;font-size:12px;}
.school-box table{width:100%;margin-top:20px;}
.school-box th{border-bottom:1px solid #ccc;height:44px;background-color:#f1f1f1;padding:0 20px;}
.school-box td{border-bottom:1px solid #ccc;height:44px;padding:0 20px;}
.school-box td button{color:#3B4894;border-bottom:1px solid #3B4894;border-radius:0;font-weight:700;}
.school-box .ex{text-align:center;}
.school-box .ex strong{color:#3B4894;border-bottom:1px solid #3B4894;}

.modal_box.leave p{font-size:16px;padding-bottom:10px;}
.modal_box.leave input{width:240px;height:40px;padding:0 10px;border-radius:4px;display:block;margin:20px auto 0 auto}
.info .title{border:0;}
.leave .out{border:1px solid #ddd;padding:20px;border-radius:12px;margin-bottom:12px;line-height:20px}
.leave .button{margin-top:32px;text-align:center;}
.leave .button button{width:200px;height:48px;color:#fff;background-color:#ccc;border-radius:4px;font-size:16px;margin:0 4px}
.leave .button button:last-child{background-color:#3B4894;}

.uptag{padding:44px 0 44px 0}
.uptag button{width:120px;height:48px;border-radius:48px;border:1px solid #ccc;color:#999;font-size:16px;margin-right:12px}

.uptag button.active, .uptag button:hover{color:#fff;background-color: #3B4894;border:1px solid #3B4894;font-weight:700}
.uptag button.u02.active, .uptag button.u02:hover{border:1px solid #e07f00;background-color:#e07f00;color:#fff;}
.uptag button.u03.active, .uptag button.u03:hover{border:1px solid #009a5a;background-color:#009a5a;color:#fff;}

.update li{display:flex;align-items: center;cursor:pointer;}
.update li span{width:100px;height:33px;background-color:#cad1f9;color:#3B4894;text-align:center;line-height:33px;border-radius:33px;font-size:14px;font-weight:700}
.update li span.u02{background-color:rgba(224,127,0,0.3);color:#e07f00;}
.update li span.u03{background-color:rgba(0,154,90,0.3);color:#009a5a;}

.update li div{vertical-align: middle;line-height:20px;display:inline-block;margin:0 0 0 20px;}
.update li h2{font-size:16px;font-weight:700;margin-top:0;}
.update li h2 > span{background-color:#fff;color:#9400d3}
.update li p{font-size:14px;font-weight:400;}
.update li button{top:30px}
.update_box .update_con{width:100%;text-align:left;}
/*페이징*/
.paging {text-align:center;padding:20px 0}
.paging img{display:inline-block;vertical-align:middle;min-width:18px;height:18px;margin:0;}
.paging a{display:inline-block !important;vertical-align:middle;min-width:18px;height:18px;line-height:20px;margin:0 5px;font-size:14px;color:#666666;font-weight:500}
.paging a.active{color:#000;font-weight:800}
.paging li{display:inline-block !important;padding:0 !important;border:0;}
.paging li.active a{color:#000;font-weight:800}
.paging li.page_first a{font-size:0px;background:url(../assets/images/paging_pprev.svg) no-repeat 0 0 / 18px;width:18px;height:18px;}
.paging li.page_prev a{font-size:0px;background:url(../assets/images/paging_prev.svg) no-repeat 0 0 / 18px;width:18px;height:18px;}
.paging li.page_next a{font-size:0px;background:url(../assets/images/paging_next.svg) no-repeat 0 0 / 18px;width:18px;height:18px;}
.paging li.page_last a{font-size:0px;background:url(../assets/images/paging_nnext.svg) no-repeat 0 0 / 18px;width:18px;height:18px;}

.input_text{position:relative;}
.input_text label{display:none;position:absolute;font-size:10px;left:0;top:0;margin:-5px 0 0 10px;background-color:#fff;padding:0 7px;}
.input_text label::after{content:'*';color:#FF0000;font-size:10px;vertical-align: middle;}
.input_text.active label{display:block}
.input_text button{position:absolute;right:10px;top:10px;display:none}
.input_text button.active{display:block;}

.input_text._pw{position:relative;margin-top:12px}
.nolist{text-align:center;font-size:16px;padding:40px 0}
.modal_box.leave input.text_pw{margin-bottom:0;}
.modal_box.leave input.text_pw{margin-top:10px;}
.out_pw{opacity:0;color:#ff0000;margin-bottom:20px;display:block;}
.out_pw.active{opacity:1;}

#naverIdLogin img{border-radius:20px}
.no-data{text-align:center;padding:20px 0}
.m_close {display:none}
.m_menu {display:none}
.m_submenu {display:none}
.m_title {display:none}
.m_image {display:none}
.header_box{width:100%;max-width:1200px;margin:0 auto;}
.header_cate_hover_menu .cate_bar_box .cate_bar .list .box .sub_box div{display:flex;align-items: center;gap: 12px;width: 100%;}

.header_youtube{}
.header_naver{margin-left:10px;}
.fav_btn{margin-left:10px;}

.btn_top{position:fixed;bottom:20px;right:10px;}
.file-down-box{display:none;position:fixed !important;width:100% !important;height:100%;left:0;top:0 !important;z-index:9999;background-color:rgba(0,0,0,0.2);}
.file-down-box.active{display:block}
.file-down-box img{position:absolute;left:50%;top:50%;transform:translate(-50%,-50%);z-index:10000;animation-name:spinCircle;animation-duration:1s;animation-iteration-count:infinite;animation-timing-function:linear;}


.title .search {float:right;position:relative;margin:0px 0 0 0}
.title .search input{width:300px;height:36px;border:1px solid #ddd;border-radius:4px;padding:0 50px 0 10px;font-size:14px}
.title .search button{width:26px;height:36px;background:transparent;border:0;position:absolute;right:1px;top:1px;margin:0;text-align:center;padding:0;}
.title .search button.text-remove{right:28px;width:20px;}




@keyframes spinCircle {
    from {
        transform:translate(-50%, -50%) rotate(0);
    }
    to {
        transform:translate(-50%, -50%) rotate(360deg);
    }
}

@media(max-width:980px){
    .se-video-container iframe{width:100% !important;height:auto !important;position:relative !important}
    .se-video-container figure{width:100% !important;height:auto !important;padding-bottom:0 !important;}
 
}
@media(max-width:800px){
    .banner_pc{display:none}
    .banner_mobile{display:block;}
    .m_close {display:block;position:absolute;right:16px;top:16px;}
    .m_menu {display:block;text-align:left;padding:0 40px;margin-top:84px}
    .m_title {display:block;}    
    .popup_container{width:92%;}
    .popup-box{width:100%}
    .changbi.pop{width:calc(100% - 40px);}
    .changbi.pop._02{width:calc(100% - 40px);}
    .changbi.pop._03{width:calc(100% - 40px);}
    .changbi.pop._04 .form.flex{display:block !important;overflow:hidden;overflow-y:auto;height:400px}
    .changbi.pop._04 .form.flex .list{width:calc(100% - 20px);margin-bottom:20px}

    .changbi.pop._02 .popup-btn{margin:0 -4px}
    .changbi.pop._02 .popup-btn button{width:calc(50% - 8px);margin:0 4px;}
    .changbi.pop._02 .popup-btn button:last-child{width:calc(100% - 8px);margin-top:8px;}

    .login_back{position:fixed;top:0;width:100%;z-index:10;padding:0;margin:0;}
    .login_box{width:100%;border-radius:0;margin:0;max-width:100%;padding-top:100px;}
    .login_box .title{font-size:24px;}
    .login_box .tab button{font-size:24px}
    .find-box{min-height:400px}
    .header{box-shadow:unset}
    .header_part3{ opacity:0;margin-left:-100%; position:fixed;background:rgba(0,0,0,0.5);width:100%;height:100%;left:0;z-index:11;}
    .header_part3.active{opacity:1;margin-left:0; }        
    .header_part3 .header_box{margin-left:-100%; transition: all 0.1s;opacity:0;background-color:#fff;left:0;top:0;width:80%;height:100%;position:absolute;overflow:hidden;overflow-y:auto}
    .header_part3.active .header_box{opacity:1;margin-left:0;}
    .header_part3_sub{display:block}
    .header_part3_cate_box{display:block;height:auto;margin-top:20px;}
    .header_part3_cate_box .tap{width:100%;border-radius:0;max-width:100%;text-align:left;justify-content:left;padding-left:48px;border-top:1px solid #fff;}   
    .header_part3_cate_box .tap.language:hover {background: url(../assets/images/check-navy.svg) no-repeat center left 20px #FFE8F0 ;}
    .header_part3_cate_box .tap.society:hover {background: url(../assets/images/check-navy.svg) no-repeat center left 20px #DCF4FF ;}
    .header_part3_cate_box .tap.art:hover {background: url(../assets/images/check-navy.svg) no-repeat center left 20px #FFF5DB ;}
    .header_part3_cate_box .tap.language.active {background: url(../assets/images/check-navy.svg) no-repeat center left 20px #FFE8F0 ;}
    .header_part3_cate_box .tap.society.active {background: url(../assets/images/check-navy.svg) no-repeat center left 20px #DCF4FF ;}
    .header_part3_cate_box .tap.art.active {background: url(../assets/images/check-navy.svg) no-repeat center left 20px #FFF5DB ;}
    .header_part3_cate_box.two{display:block;margin-top:20px;padding-bottom:20px;}
    .header_part3_cate_box .tap.two{width:calc(100% - 40px);padding:15px 20px;font-size:20px;height:auto;margin:0 20px}
    .header_part3_cate_box .tap.two:hover{background:transparent;color:#2F3444}

    .m_menu button.close{position:absolute;right:20px;top:20px;}
    .m_menu a, .m_menu .logout{font-size:20px;display:block; text-align:left;padding:12px 0px;font-weight:500;}
    .m_menu div{width:100%;}    
    .m_menu div a{display:inline-block}
    .m_menu a.register{float:right;background-color:#3B4894;border-radius:48px;width:134px;color:#fff;font-size:20px;display:inline-block; text-align:center;font-weight:500;}
    .m_submenu {display:block;margin:0 20px 20px 20px;width:calc(100% - 40px);height:40px;border-radius:4px;border:1px solid #ddd;padding:0 10px}
    .cate_bar .menu_info{padding:0px}
    .btn-menu1{display:none}
    .menu_info .box-menu1{left:0;border-left:0;padding:0px 20px 0px 20px;width:100%;}
    .menu_info .scroll-menu2{margin-bottom:10px;width:100%;padding-bottom:10px}
    
    .sns_box{font-size:16px;padding:50px 0}
    .hidemenu button{font-size:20px;display:block; text-align:left;padding:12px 0px;font-weight:500;}
    .hidemenu ul{margin-bottom:10px;display:none}
    .hidemenu ul.open{display:block}
    .hidemenu li{padding:5px 0 5px 20px;font-size:16px;font-weight:500}

    .popup-main{left:0;top:0;transform:translate(0,50%);width:calc(100% - 40px);left:20px;z-index:9;}
    .popup-main a img{width:100%;}
    .popup-main img{width:100%;}
    .popup-main button img{width:auto;}

    .cate_bar{position:relative;}
    .header_cate_hover_menu{top:calc(100% + 120px);width:100%;z-index:12;}
    .header_cate_hover_menu.scroll{top:calc(100% + 104px)}
    .header_cate_hover_menu .cate_bar_box .cate_bar{display:block;}
    .header_cate_hover_menu .cate_bar_box .cate_bar .class {display:none}
    .header_cate_hover_menu .cate_bar_box .cate_bar .list{padding:0;gap:20px;}
    .header_cate_hover_menu .cate_bar_box .cate_bar .list .box{padding:0;margin:0 20px;}
    .header_cate_hover_menu .cate_bar_box .cate_bar .list .box .sub_box{display:block;overflow:hidden;overflow-x:auto;width:100%;}
    .header_cate_hover_menu .cate_bar_box .cate_bar .list .box .sub_box div{width:max-content;}
    .header_cate_hover_menu .cate_bar_box .cate_bar .list .box .sub_box p{width:120px;height:43px;text-align:center;padding:12px 0;font-size:16px;display:inline-block;}
    .header_cate_hover_menu .cate_bar_box .cate_bar .list .exit_box{position:absolute;bottom:0;}

    
    .course {margin-top:110px;box-shadow:unset}   
    .course-top{margin:0px 0 0 0}
    .course-title {display:block;text-align:left;}
    .course-title .book_img{display:inline-block;vertical-align:top;margin:0 0 0 20px;width:96px;height:120px}
    .course-title p{display:inline-block;vertical-align:top;margin:0 0 0 20px;}
    .course-title h1{font-size:24px}
    .course-title .view{display:block;position:relative;bottom:unset;width:100%;left:0;padding:0 16px;margin-top:20px;}
    .course-title .view a{width:calc(100% - 8px);margin:0px 4px 8px 4px; }
    .course-title .view a.btn_dvd{width:calc(50% - 8px);}
    .course-title .view button{width:calc(50% - 8px);display:inline-block;margin:0 4px}

    
    .course-button{display:block;position:relative;width:100%;padding:0 16px 12px 16px;margin-top:20px;}
    .course-button button{width:calc(50% - 8px);margin:0 4px;margin-bottom:8px}
    .course-button a{width:calc(50% - 8px);margin:0 4px;margin-bottom:8px;display:inline-block;border-radius: 5px;}

    .course-detail{display:block;padding:0;margin:0;}
    .course-menu{width:100%;padding:20px;}
    .course-menu dl{display:none;overflow:hidden;height:40px;padding:0;margin:0 0 8px 0;border-radius:4px;}    
    .course-menu dt, .course-menu button{height:40px;border:0;padding:0 12px;margin:0;font-size:14px;line-height:40px;background:url(../assets/images/chevron-down.svg) no-repeat right 10px center;}
    .course-menu dd{height:40px;border:0;padding:0 12px;margin:0;font-size:14px;line-height:40px;cursor:pointer}
    .course-menu button{border:1px solid #ddd;border-radius:4px;}
    .course-menu ul.hide{display:none}
    .course-menu select{padding:0 10px;display:block;height:40px;margin:0 0 8px 0;border-radius:4px;width:100%;}

    .course-tab{padding:0 0px;margin:0;width:100%;}
    .course-tab h2{font-size:24px;padding:0 20px;}
    .course-tab .tab{display:block;padding:0 20px;}
    .course-tab .tab button{width:calc(50% - 1px);border:1px solid #f1f1f1;margin:0 1px 1px 0;}
    .course-tab .tab button.active{border:1px solid #3B4894}
    .course-tag{overflow:hidden;overflow-x:auto;margin:30px 0px 10px 0px;padding-bottom:10px;}
    .course-tag ul{width:max-content;margin:0 12px 0 20px;}
    .list dd{display:flex;align-items: center;}
    .list dd img{height:22px;}
    .list dd span{width:calc(100% - 140px);display: -webkit-box;-webkit-line-clamp: 1;-webkit-box-orient: vertical;overflow: hidden;text-overflow: ellipsis;word-break: break-word;}

    .wrap{display:block;margin:120px 0 0 0}
    .event .wrap{margin-top:140px}
    .inno-menu {width:100%;padding:0 20px;margin:0px 0 0 0;}
    

    .inno-menu h1{font-size:24px;font-weight:700;margin-bottom:20px;}
    .inno-menu dl{overflow:hidden;height:40px;padding:0;margin:0 0 8px 0;border-radius:4px;background:url(../assets/images/chevron-down.svg) no-repeat right 10px top 10px;}    
    .inno-menu dt{height:40px;border:0;padding:0 12px;margin:0;font-size:14px;line-height:40px;background:transparent}
    .inno-menu dd{height:40px;border:0;padding:0 12px;margin:0;font-size:14px;line-height:40px}    
    .inno-menu dd span{font-size:14px;padding:0 0 0 20px;height:40px;line-height:40px;display:block}

    .inno-menu dl.active{height:auto;background:url(../assets/images/chevron-up.svg) no-repeat right 10px top 10px;}
    .inno-menu dl.active dd{height:auto}
    .inno-menu ul.active {padding:0;margin:-10px 0 30px 0;}
    .inno-menu ul.active li{padding-left:30px;height:30px;}

    .inno-menu select{padding:0 10px;display:block;overflow:hidden;height:40px;margin:0 0 8px 0;border-radius:4px;background:url(../assets/images/chevron-down.svg) no-repeat right 10px top 10px;width:100%;}
    .inno-menu dl{display:none}

    .changbi .box {width:100%;padding:0 0px;margin:0;}
    .changbi .box.inno-banner{padding-top:10px}
    
    .inno-banner div{margin:0;display:block;padding:0 20px}
    .inno-banner a{margin:0 0 20px 0;display:block}
    

    .wrap .title{font-size:24px;margin-top:10px;padding:0 0px;margin-left:20px;margin-right:20px}
    .wrap .comment{line-height:20px;padding:0 20px;word-break:normal}
    .wrap .tab{display:block;padding:0 20px}
    .wrap .tab button{width:16.66%;border:0px;position:relative;margin:0;font-size:14px;height:36px;line-height:36px;border-right:1px solid #fff;border-bottom:1px solid #fff}
    .wrap .tab button.active{border:1px solid #3B4894;z-index:9;background-color:#fff;}

    .list-type3{display:block;padding:0 20px}
    .list-type3 .type0{width:calc(100% - 20px);}
    .list-type3 .type1{width:calc(100% - 20px);}
    .list-type3 .type2{width:calc(100% - 20px);}
    .list-type3 h2{border-radius:10px 10px 0 0}    
    .list-type3 ul{padding:20px 20px 0 20px}
    .list-type3 .scroll{height:392px;}
    .wrap .category{border:0;padding:0 20px;display:block}
    .wrap .category div{display:block}
    .wrap .category label{display:block;}
    .wrap .category button{width:33.33%}
    .wrap .category.even button{width:calc(50% - 12px);display:inline-block;margin-bottom:10px}
    .wrap .category.odd button{width:calc(50% - 12px);display:inline-block;margin-bottom:10px}
    .wrap .category.odd button:first-child{width:calc(100% - 12px);}

    .wrap .category button{width:auto;padding:0 15px}
    .list-faq .a-faq{position:relative;display:block;margin:30px 0 0 20px}
    .wrap .category div:last-child button{width:16.66%;letter-spacing: -1px;}
    .list-type2 {border:0;padding:0 20px}
    .list-type2 .total{border-bottom:0;height:30px;}
    .list-type2 ul{padding:0;margin:0;}
    .list-type2 li{width:100%;margin:0 0 20px 0;}
    .info-image{width:calc(100% - 40px);display:none;margin:0 20px}
    .m_image {display:block}
    .info-title{display:flex;justify-content: center;align-items: center;margin:20px 20px 0 20px;padding:12px 0;height:auto}
    .info-title small{width:37px;display:block;vertical-align: middle;}
    .info-title span{width:calc(100% - 161px);vertical-align: middle;word-break:keep-all;display:block;line-height:20px;}
    .info-title button{position:relative;width:77px;vertical-align: middle;bottom:unset;right:unset;margin:0;}
    .info-editor{padding:20px}
    .offer-data{margin:0 20px}
    .offer-data dd{padding:0;margin:0;}
    .info-button{margin:0px 0;clear:both;padding:20px 0}
    .list-button {margin:0 -4px 0px -4px;padding:0 20px}    
    .list-button button{width:calc(50% - 8px);margin:0 4px;margin-bottom:8px}
    .list-button button.dic{width:calc(100% - 8px);}
    .list .search{left:0;margin-top:-88px;width:100%;position:absolute;padding:0 20px}
    .list .search input{width:100%;}
    .list .search button{right:20px}    
    .list .search button.text-remove{right:44px}
    .title .search{left:0;width:100%;position:relative;padding:0 0px;margin-top:5px;margin-bottom:10px;}
    .title .search input{width:100%;padding-right:60px}
    .title .search button{right:10px;top:-2px;}    
    .title .search button.text-remove{right:34px}
    .dictionary .list{margin-top:70px}
    .wrap .tab._01{margin-top:10px}
    .wrap .tab._02{margin-top:10px}
    .wrap .tab._01 button{width:25%;border-bottom:0;height:52px;line-height:52px}   
    .wrap .tab._02 button{width:33.33%;border-bottom:0;height:52px;line-height:52px}
    .wrap .tab._03 button{width:25%;border-bottom:0;height:52px;line-height:52px}
    .list-button._01 button{width:calc(100% - 8px);}

    .dict-main .dict1{display:block;}
    .dict-main .dict1 div{width:calc(100% - 60px);margin:0 auto 20px auto}    
    .dict-main .dict2{display:block;}
    .dict-main .dict2 button{display:block;width:calc(100% - 60px);margin:0 auto 20px auto}    
    .etc-menu {width:100%;padding:0 20px;margin:0px 0 0 0;}
    .etc-menu h1{font-size:24px;font-weight:700;margin-bottom:20px;}
    .etc-menu select{width:100%;padding:0 10px;display:block;height:40px;margin:0 0 8px 0;border-radius:4px;background:url(../assets/images/chevron-down.svg) no-repeat right 10px top 10px;}
    .etc-menu dl{display:none;overflow:hidden;height:40px;padding:0;margin:0 0 8px 0;border-radius:4px;background:url(../assets/images/chevron-down.svg) no-repeat right 10px top 10px;}    
    .etc-menu dt{display:block;height:40px;border:0;padding:0 12px;margin:0;font-size:14px;line-height:40px}    
    .etc-menu dd {font-size:14px;padding:0 0 0 20px;height:40px;line-height:40px;display:block;border:0;}
    .etc-menu dd span{font-size:14px;border:0;}
    .etc-menu dl.active{height:auto;background:url(../assets/images/chevron-up.svg) no-repeat right 10px top 10px;}
    .etc-menu dd.active{background:transparent;border:0;}

    .list-type1{border-bottom:0;}
    .list-type, .list-type41{border-bottom:0;padding:0;width:100%;}
    .list-type1 ul, .list-type4 ul{padding:0 20px;width:100%;margin:20px 0 0 0;}
    .list-type1 h2, .list-type4 h2{margin-bottom:0;margin-left:0;}
    .list-type1 li, .list-type4 li{width:100%;margin:0 0 20px 0;}
    .list-type1 img, .list-type4 img{width:100%;display:inline-block;height:auto;}
    .list-type4 p{margin-left:0;}
    .wrap .m-view{padding:0 20px;}
    .wrap .m-view .title{padding-left:0;padding-right:0;margin-left:0;margin-right:0;}
    .view .sub{height:auto;padding-bottom:12px;padding-top:0;}
    .view .sub div{display:block;float:unset;margin-top:12px;}
    .view .sub button:first-child{margin-left:0;}

    .my-menu{display:none}
    .navi {box-shadow:unset;}
    .navi div{margin-left:20px}
    .board{margin-top:0px;}
    .board.notice{margin-top:150px;}
    .board.faq{margin-top:150px;}
    
    .board h1{font-size:24px;padding:10px 0 0 0px;margin-left:20px;margin-right:20px;margin-top:10px;}
    .board h1.notice{margin-top:0px;}
    .board.view{margin-top:150px;}
    .board.view .info-editor{margin:0 20px}
    .board.view .preview{margin:0 20px}
    .my.faq{margin-top:0px}
    .my h1.title{margin:0 20px;width:calc(100% - 40px);height:auto;padding-bottom:20px}
    .my ul{margin:0 20px}
    .my li{padding:20px 0}
    .my li div{margin-top:17px}
    .my li h2{font-size:20px}
    .my li button{right:0}
    .login_box .title_box.register .title{padding-bottom:8px}

    .modal_box{width:calc(100% - 40px);margin:0 auto;min-width:auto}
    .modal_box p{font-size:16px;}
    .modal_box div button{width:calc(50% - 8px);margin:0 4px;}
    
    .changbi.pop .form{padding:20px}
    .changbi.pop .tag ul {margin:0 0 0 -8px}
    .changbi.pop .tag li{width:calc(33.33% - 8px);margin:0 0 8px 8px}   
    .changbi.pop._02 .list dd{padding-top:0;}
    .changbi.pop._03 .list dd{padding-top:0;}
    .pop .my ul{margin:0;padding-top:0;}
    .changbi.pop .popup-btn {margin:20px -4px 0 -4px}    
    .changbi.pop .popup-btn button{width:calc(50% - 8px)}
    .board .yscroll{overflow:hidden;overflow-x:auto;width:100%;margin-bottom:20px}
    .board .yscroll table{width:600px;margin:10px 0 0 0;}
    .list dd div{top:13px;}
    .wrap.scrap .category {margin-bottom:10px;border:1px solid #eee;border-left:0;border-right:0;border-radius:0;padding-top:10px;padding-bottom:10px;}
    .wrap.scrap .category label{display:inline-block;margin-top:0;}
    .wrap.scrap .tabs01 div{display:inline-flex;width:calc(100% - 56px);}
    .wrap.scrap .category .tabs01 button{width:50%;}
    .wrap.scrap .tabs02{align-items: center;justify-items: center;display:flex;}
    .wrap.scrap .tabs02 div.stab0{display:inline-block;width:calc(100% - 56px);}
    .wrap.scrap .tabs02 div.stab0 button{width:50%;}
    .wrap.scrap .tabs02 div.stab1{display:inline-block;width:calc(100% - 56px);}
    .wrap.scrap .tabs02 div.stab1 button{width:50%;margin-bottom:1px}
    .board-button{padding:0 20px }
    .wrap.scrap .board{margin-top:0px} 

    .my{margin-top:0px} 

    .qna{width:100%}
    .qna form{padding:0 20px}
    .my.qna h1.title{height:auto;padding-bottom:10px;padding-top:5px}
    .qna select{width:100%}    
    .qna .file label{width:80px}
    .qna .file span{font-size:12px}
    .write_button {margin:20px -4px}
    .write_button button{width:calc(50% - 8px);margin:0 4px}
    .my .w100{}
    .my .write{padding-left:20px}
    .my .reply{padding:10px 20px 20px 45px;background:url(../assets/images/icon_faq.svg) no-repeat 20px 10px / 20px;}
    .list-faq .dt-faq {height:90px;padding:10px 20px 0 20px}
    .list-faq .dt-faq form{display:block;width:calc(100% - 40px);left:20px;}
    .list-faq .dt-faq input{width:100%;}

    .info{padding-bottom:20px;margin-top:0px;}
    .info h1{margin:0 20px;font-size:24px;height:auto;padding-top:5px}
    .info h1 button{font-weight:700}
    .info h2{font-size:20px;margin:20px 20px 0px 20px}
    .info table{margin:0 20px;width:calc(100% - 40px)}
    .info .basic th, .info .marketing th {height:49px;width:100px;padding:0 10px}
    .info th{height:49px}
    .info td{height:49px;padding:4px 0 4px 10px}
    .info td small{margin-left:3px;letter-spacing:-0.5px}
    .info td.td-flex{align-items: center;}
    .info td.td-flex input{width:calc(100% - 84px);display:inline-block}
    .info td.td-flex span{width:calc(100% - 90px);display:inline-block;letter-spacing:-0.5px;}
    .info td.td-flex button{width:80px;display:inline-block}
    .info .sns col:first-child{width:60px}

    .info-btn{width:100%;margin:20px auto;padding:0 20px}
    .info-btn button{height:48px;width:100%;}

    .school-box table{margin-bottom:20px}
    .school-box th{padding:0 10px}
    .school-box td{padding:0 10px}
    .school-box col:first-child{width:100px}
    .school-box col:last-child{width:50px}
    .leave .out{margin:0 20px}
    .leave .check{margin:12px 0 0 20px;}
    
    .leave .button {padding:0 20px;margin:20px -4px}
    .leave .button button{width:calc(50% - 8px);margin:0 4px}

    .uptag{padding: 20px;display:flex;margin:0 -4px}
    .uptag button{width:calc(33.33% - 8px);margin:0 4px}
    ul.update {margin-bottom:20px}
    .update li {display:block}
    .update li span{display:block}
    .update li div{margin-left:0;width:calc(100% - 64px);margin-top:5px}
    .update li button{top:52px}

    .popup_register{width:calc(100% - 40px);}
    .list-faq .dd-faq{height:auto;}
    .list-faq .h2-faq{height:auto;padding-bottom:8px;padding-right:40px}
    .preview dt{float:left}
    .preview dd{float:left}
    .preview dd a{display: -webkit-box;-webkit-line-clamp: 1;-webkit-box-orient: vertical;overflow: hidden;text-overflow: ellipsis;word-break: break-word;}
    .navi span {float:left;}
    .navi span.event_title{overflow: hidden;display:inline-block;text-overflow: ellipsis;width:calc(100% - 120px);white-space: nowrap;}
}

@media(max-width:600px){
.mscroll{overflow:hidden;overflow-x:scroll;}
.mscroll dl{width:600px}
.my li h2{-webkit-line-clamp: 2;line-height:22px}
}
@media(max-width:500px){
    .agree_chk button{padding:8px 10px}
    .changbi.pop .tag label{width:40px}
    .changbi.pop .tag ul{width: calc(100% - 0px);}
    .changbi .check{margin-right:10px}
    .list dt .check span{margin-left:5px}
    .list dt button{padding:0 10px;margin-right:5px}
    .list dt{padding:0 14px}
    .list dd{padding:0 14px}
    .header_top_sub .fav_btn{display:none}
    .subject_box .img_box img{width:100%;}
    .subject_box .img_box{padding:0;}
    .m_menu a.register{width:auto;padding:12px 15px}
    .list-button button{letter-spacing:-1px}
}


@media(max-width:370px){
    .login_box{padding:40px 20px}
    .wrap .category.even button{padding:0 10px;letter-spacing:-0.5px;}
    .list-button button{padding:0 10px}
    .list-button button img{display:none;}
    .list dt button{padding:0 3px}
}